import React, {useEffect, useState} from 'react';
import Moveable from "react-moveable";
import Cline from "../../../assets/images/previews/cline.png"
import Line from "../../../assets/images/previews/line.png"
import P2p from "../../../assets/images/previews/p2p.png"
import Point from "../../../assets/images/previews/point.png"
const WaveFrom = (props) => {
    const [imageState, setImageState] = useState({
        top: props.wave.posy,
        left: props.wave.posx,
        width: props.wave.width,
        height: props.wave.height,
        mode:props.wave.mode
    });
    const frame = {
        translate: [0, 0],
    };
    const imageObject = imageState.mode === "cline" ? Cline : imageState.mode === "line" ? Line : imageState.mode === "p2p" ? P2p : imageState.mode === "point" ? Point : null

    useEffect(() => {
        setImageState({
            top: props.wave.posy,
            left: props.wave.posx,
            width: props.wave.width,
            height: props.wave.height,
            mode:props.wave.mode
        })
    },[props.wave.mode])
    return (
        <>

                <img src={imageObject} alt="Layer"/>
        </>
    );
};

export default WaveFrom;
