import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import {Helmet} from "react-helmet";
import Alert from "../Alert/Alert";
import Footer from "../Footer/Footer";
import ResellerAccount from "./ResellerAccount";
import axios from "axios";
import {baseURL, config} from "../../globals/global";
import {removeLoader, setLoader} from "../../actions/loader";
import {setAlert} from "../../actions/alert";
import {connect} from "react-redux";

const Reseller = (props) => {

    const [accounts, setAccounts]  = useState([])

    const [formValue, setFormValue] = useState({
        name:"",
        email:"",
        password:""
    })
    const handleChange = (e) => {
        setFormValue({
            ...formValue,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.setLoader();
        try{
            axios.post(`${baseURL}reseller`, formValue,config).then((res)=>{
                if(res.data.status === true)
                {
                    fetchReseller();
                    props.setAlert(res.data.message,'success');
                    props.removeLoader();
                }
                else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger');
                    props.removeLoader();
                }
                else
                {
                    props.setAlert('Something went wrong','danger');
                    props.removeLoader();
                }
            }).catch((error) => {
                console.log(error,'Something went wrong')
                props.removeLoader();
            })
        }catch (error){
            console.log(error,'Something went wrong')
            props.removeLoader();
        }
    }

    const fetchReseller = () => {
        console.log('called');
        try{
            axios.get(`${baseURL}fetch-reseller-accounts`).then((res)=>{

                setAccounts(res.data.accounts)
            })
        }catch (error){
            console.log(error)
        }
        return () => {

        };
    }
    useEffect(() => {
        fetchReseller();
    }, []);
    return (
        <>
            <Header />
            <Helmet>
                <title>Sonority | Reseller Accounts </title>
            </Helmet>
            <section className="add-account-sec" style={{height: "auto"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add-heading text-white">
                                <h4>Create Reseller Account</h4>
                            </div>
                        </div>
                    </div>
                    <Alert />
                    <div className="row">
                        <div className="col-md-12">
                            <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                                <div className="add-box text-white">
                                    <div className="row mt-5">
                                        <div className="col-md-4">
                                            <div className="form-group ">
                                                <label htmlFor="inputPassword4">Name</label>
                                                <input type="text" className="form-control" name="name" id="inputPassword4" placeholder="Enter Client Name" onChange={(e) => handleChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group ">
                                                <label htmlFor="inputPassword4"> Email Address</label>
                                                <input type="text" className="form-control" name="email" id="inputPassword4" placeholder="your email" onChange={(e) => handleChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group ">
                                                <label htmlFor="inputEmail4">Password</label>
                                                <input type="password" className="form-control" name="password" id="inputPassword4" placeholder="........" onChange={(e) => handleChange(e)}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <div className="save-btn">
                                                <button className="btn-change8" type="submit">{props.loader ? <i className="fa fa-spinner fa-spin mr-2"/> : null} Create Account</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ResellerAccount accounts={accounts} fetchReseller={fetchReseller}/>
                </div>
            </section>
            <Footer />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        setAlert: (message,type) => dispatch(setAlert(message,type)),
        removeLoader: () => dispatch(removeLoader())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    Reseller
);
