import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { removeLoader, setLoader } from "../../actions/loader";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";
import ATrack from "./ATrack";
import axios from "axios";
import { baseURL, config } from "../../globals/global";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert/Alert";
import { Link } from "react-router-dom";
import TrackFile from "../MyTracks/TrackFile";
import { Helmet } from "react-helmet";
import * as queryString from "query-string";

function AudioTrack(props) {
  const [tracks, setTracks] = useState({
    merge: [],
    tts: [],
    music: [],
  });
  const [sounds, setSounds] = useState([]);
  const [title, setTitle] = useState({
    id: "",
    title: "",
  });
  const [localTracks, setLocalTracks] = useState([]);
  const [background, setBackground] = useState([]);

  useEffect(() => {
    setTracks({
      merge: props.tracks.merge,
      tts: props.tracks.tts,
      music: props.tracks.music,
    });
  }, [props.tracks]);

  useEffect(() => {
    props.fetchTracks();
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}fetch-sounds`, config)
        .then((res) => {
          if (res.data.status === true) {
            setSounds(res.data.data);
          } else {
            props.setAlert("Not able to Fetch Sounds. Please reload", "danger");
          }
        })
        .catch((error) => {});
    } catch (err) {
      console.log(err);
    }
  }, []);

  const audioTrackId = queryString.parse(props.location.search).id;

  useEffect(() => {
    if (props.tracks.merge.length > 0) {
      if (audioTrackId !== undefined) {
        const rowIdDetails = props.tracks.merge.find(
          (item) => item.id === audioTrackId
        );
        console.log(rowIdDetails, "Check ROw");
        if (rowIdDetails !== undefined) {
          console.log("Edit Loaded");
          setTitle({
            id: rowIdDetails.id,
            title: rowIdDetails.name,
          });
          setLocalTracks(JSON.parse(rowIdDetails.input_audio));

          setBackground([JSON.parse(rowIdDetails.background)]);
        }
      }
    }
  }, [audioTrackId, props.tracks.merge.length]);

  const handleTitleChange = (e) => {
    setTitle({
      ...title,
      title: e.target.value,
    });
  };

  const handleDragEnd = (result) => {
    console.log(result);
    if (result.destination !== null) {
      if (result.destination.droppableId === "tracks") {
        // move the item to localTracks Array
        console.log("Tracks Local");
        const localTrackItems = Array.from(localTracks);
        let removedItem = "";
        if (result.source.droppableId === "VoiceTracks") {
          const removedItemInitial = tracks.tts.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.title,
            volume: 80,
            audio: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "AudioTracks") {
          const removedItemInitial = tracks.merge.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.name,
            volume: 80,
            audio: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "MusicTracks") {
          const removedItemInitial = tracks.music.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.title,
            volume: 80,
            audio: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "soundEffect") {
          const removedItemInitial = sounds.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: "Sound",
            name: removedItemInitial.name,
            volume: 80,
            audio: removedItemInitial.url,
          };
        }
        if (result.source.droppableId === "tracks") {
          const removedItemInitial = localTrackItems.splice(
            result.source.index,
            1
          );
          removedItem = removedItemInitial[0];
        }
        localTrackItems.splice(result.destination.index, 0, removedItem);
        setLocalTracks(localTrackItems);
      }
      if (result.destination.droppableId === "background") {
        // move the item to background Array
        let removedItem = "";
        if (result.source.droppableId === "VoiceTracks") {
          const removedItemInitial = tracks.tts.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.title,
            volume: 80,
            music: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "AudioTracks") {
          const removedItemInitial = tracks.merge.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.name,
            volume: 80,
            music: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "MusicTracks") {
          const removedItemInitial = tracks.music.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: removedItemInitial.type,
            name: removedItemInitial.title,
            volume: 80,
            music: removedItemInitial.output_audio,
          };
        }
        if (result.source.droppableId === "soundEffect") {
          const removedItemInitial = sounds.find(
            (item, index) => index === result.source.index
          );
          removedItem = {
            id: removedItemInitial.id,
            type: "Sound",
            name: removedItemInitial.name,
            volume: 80,
            music: removedItemInitial.url,
          };
        }
        if (result.source.droppableId === "tracks") {
          const localTrackItems = Array.from(localTracks);
          const removedItemInitial = localTrackItems.splice(
            result.source.index,
            1
          );
          removedItem = removedItemInitial[0];
        }
        console.log(removedItem);
        setBackground([removedItem]);
      }
    } else {
      console.log("Nothing to Add or Remove");
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    props.setLoader();
    const body = {
      id: title.id,
      name: title.title,
      audios: localTracks,
      background: { ...background[0] },
    };
    try {
      axios
        .post(`${baseURL}merge-audio`, body, config)
        .then((res) => {
          if (res.data.status === true) {
            props.removeLoader();
            props.setAlert("Audios has been merged", "success");
          } else if (res.data.status === false) {
            props.removeLoader();
            props.setAlert(res.data.message, "danger");
          } else {
            props.removeLoader();
            props.setAlert("Something went wrong", "danger");
          }
        })
        .catch((error) => {
          props.removeLoader();
        });
    } catch (err) {
      console.log(err);
      props.removeLoader();
    }
  };

  const handleRemoveClick = (e, id, type) => {
    if (type === "track") {
      const lclArray = Array.from(localTracks);
      const finalArray = lclArray.filter((item) => item.id !== id);
      setLocalTracks(finalArray);
    }
    if (type === "background") {
      const lclArray = Array.from(background);
      const finalArray = lclArray.filter((item) => item.id !== id);
      setBackground(finalArray);
    }
  };

  const handleVolumeControl = (volume, id, mergeType) => {
    console.log(volume, id, mergeType);
    if (mergeType === "track") {
      const newTrackLocalTrack = localTracks.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            volume: volume,
          };
        } else {
          return item;
        }
      });

      setLocalTracks(newTrackLocalTrack);
    }
    if (mergeType === "background") {
      const newBackgroundTrack = background.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            volume: volume,
          };
        } else {
          return item;
        }
      });
      setBackground(newBackgroundTrack);
    }
  };

  return (
    <>
      <Header />
      <Helmet>
        <title>Sonority | Audio Track </title>
      </Helmet>
      <section className="audio-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="audio-track text-white">
                <h4>Audio Track</h4>
                <div className="form-group mt-4">
                  <label htmlFor="exampleInputEmail1">Campaign Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Give a name to your audio track"
                    value={title.title}
                    onChange={(e) => handleTitleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Alert />
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="audio-track text-white">
                  <h4>Sound Effects</h4>
                  <div className="row">
                    <Droppable droppableId="soundEffect">
                      {(provided) => (
                        <div
                          className="row droppable soundsRow"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {sounds.length > 0 ? (
                            sounds.map((item, index) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={"sounds" + item.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="col-lg-4 col-md-6 sr"
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <ATrack
                                        key={"sound" + item.id.toString()}
                                        item={item}
                                        type="Sound"
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })
                          ) : (
                            <p className="text-center text-white">
                              No sound Effects
                            </p>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5  ">
              <div className="col-md-8 ">
                <div className="background-tracks">
                  <h5 className="text-white">Background</h5>
                  <div className="background">
                    <Droppable droppableId="background">
                      {(provided) => (
                        <div
                          className="droppable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {background.length > 0 ? (
                            background.map((item, index) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId="background"
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <ATrack
                                        volumeControl={handleVolumeControl}
                                        removeClick={handleRemoveClick}
                                        key={"background" + item.id.toString()}
                                        item={item}
                                        type={item.type}
                                        fileType="big"
                                        mergeType="background"
                                      />
                                      {console.log(item)}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })
                          ) : (
                            <p className="text-center text-white">
                              No tracks in background. Drag and drop a track
                            </p>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <h5 className="mt-5 text-white">Tracks</h5>
                  <Droppable droppableId="tracks">
                    {(provided) => (
                      <div
                        className="droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {localTracks.length > 0 ? (
                          localTracks.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"tracks" + item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ATrack
                                      volumeControl={handleVolumeControl}
                                      removeClick={handleRemoveClick}
                                      key={"tracks" + item.id.toString()}
                                      item={item}
                                      type={item.type}
                                      fileType="big"
                                      mergeType="track"
                                    />
                                    {console.log(item)}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <p className="text-center text-white">
                            No tracks to merge. Drag and drop a track
                          </p>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="pre-save">
                      {/*<a href="#" className="btn-change7"><span><i className="fas fa-play-circle" /></span>Preview</a>*/}
                      <a
                        className="btn-change7"
                        onClick={(e) => handleSaveClick(e)}
                      >
                        <span>
                          {props.loader === true ? (
                            <i className="fa fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="fas fa-save" />
                          )}
                        </span>
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="track-box">
                  <h5 className="text-center text-white">Music Track</h5>
                  <Droppable droppableId="MusicTracks">
                    {(provided) => (
                      <div
                        className="droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tracks.music.length > 0 ? (
                          tracks.music.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"music" + item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ATrack
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      item={item}
                                      type="Music"
                                      fileType="small"
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <p className="text-center text-white">
                            No tracks found in audio track
                          </p>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>

                <div className="track-box">
                  <h5 className="text-center text-white">Voice Track</h5>
                  <Droppable droppableId="VoiceTracks">
                    {(provided) => (
                      <div
                        className="droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tracks.tts.length > 0 ? (
                          tracks.tts.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"voice" + item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ATrack
                                      ref={provided.innerRef}
                                      item={item}
                                      type="Voice"
                                      fileType="small"
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <p className="text-center text-white">
                            No tracks found in audio track
                          </p>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>

                <div className="track-box">
                  <h5 className="text-center text-white">Audio Tracks</h5>
                  <Droppable droppableId="AudioTracks">
                    {(provided) => (
                      <div
                        className="droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tracks.merge.length > 0 ? (
                          tracks.merge.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"audio" + item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ATrack
                                      item={item}
                                      type="Audio"
                                      fileType="small"
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <p className="text-center text-white">
                            No tracks found in audio track
                          </p>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </section>

      <section className="sec-2">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Your Audio <span>Tracks</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.merge.length > 0 ? (
              tracks.merge.map((item) => (
                <TrackFile key={item.id} item={item} type="Audio" />
              ))
            ) : (
              <p className="text-center text-white">
                No tracks found in voice track
              </p>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    removeLoader: () => dispatch(removeLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AudioTrack);
