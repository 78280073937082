import "./App.css";
import "./responsive.css";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";

import { connect, Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import { useEffect } from "react";
import PrivateRoute from "./Components/routing/PrivateRoute";
import Login from "./Components/Login/Login";
import MasterLogin from "./Components/MasterLogin/MasterLogin";
import MyAccount from "./Components/MyAccount/MyAccount";
import VoiceTrack from "./Components/VoiceTrack/VoiceTrack";
import Accounts from "./Components/Accounts/Accounts";
import PrivacySettings from "./Components/PrivacySettings/PrivacySettings";
import Training from "./Components/Training/Training";
import Upgrades from "./Components/Upgrades/Upgrades";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import MusicTrack from "./Components/MusicTrack/MusicTrack";
import AudioTrack from "./Components/AudioTrack/AudioTrack";
import WaveVideo from "./Components/WaveVideo/WaveVideo";
import MyTracks from "./Components/MyTracks/MyTracks";
import Article from "./Components/Training/Article";
import ArticleDetails from "./Components/Training/ArticleDetails";
import ResellerAccount from "./Components/Reseller/ResellerAccount";
import Reseller from "./Components/Reseller/Reseller";
import UploadTranscribe from "./Components/UploadTranscribe/UploadTranscribe";
import EditTranscribe from "./Components/UploadTranscribe/EditTranscribe";
import PasswordReset from "./Components/PasswordReset/PasswordReset";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App(props) {
  useEffect(() => {
    props.loadUser();
  });
  return (
    <div className="App">
      <BrowserRouter>
        <Router>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/master-login" component={MasterLogin} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/reset-password" component={PasswordReset} />
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/profile" component={MyAccount} />
            <PrivateRoute exact path="/voice-track" component={VoiceTrack} />
            <PrivateRoute exact path="/accounts" component={Accounts} />
            <PrivateRoute exact path="/gdpr" component={PrivacySettings} />
            <PrivateRoute exact path="/training" component={Training} />
            <PrivateRoute exact path="/upgrade" component={Upgrades} />
            {/* <PrivateRoute exact path="/music-track" component={MusicTrack} /> */}
            <PrivateRoute exact path="/audio-track" component={AudioTrack} />
            <PrivateRoute exact path="/wave-video" component={WaveVideo} />
            <PrivateRoute exact path="/my-tracks" component={MyTracks} />
            <PrivateRoute exact path="/article" component={Article} />
            <PrivateRoute
              exact
              path="/article-details"
              component={ArticleDetails}
            />
            <PrivateRoute exact path="/reseller" component={Reseller} />
            <PrivateRoute
              exact
              path="/upload-n-transcribe"
              component={UploadTranscribe}
            />
            <PrivateRoute
              exact
              path="/edit-transcribe"
              component={EditTranscribe}
            />
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => dispatch(loadUser()),
  };
};
export default connect(null, mapDispatchToProps)(App);
