import React, { useEffect, useState } from "react";
import FeaturesContent from "./FeaturesContent";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import DashCount from "./DashCount";
import icon1 from "../../assets/images/icon1.png";
import ArrowIcon from "../../assets/images/arrowicon.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";
import icon4 from "../../assets/images/icon4.png";
import { setLoader } from "../../actions/loader";
import { fetchTracks } from "../../actions/tracks";
import { connect, useSelector, useDispatch } from "react-redux";
import TrackFile from "../MyTracks/TrackFile";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import VideoFile from "../MyTracks/VideoFile";
import { getBrandingDataFromServer } from "../../actions/branding";

function Dashboard(props) {
  const [tracks, setTracks] = useState({
    merge: [],
    tts: [],
    music: [],
    podcast: [],
  });
  const [show, setShow] = useState(false);
  const brandingData = useSelector((state) => state.brandingdata);
  const dispatch = useDispatch();
  const [brandingName, setBrandingName] = useState("");
  useEffect(() => {
    setTracks({
      merge: props.tracks.merge.slice(0, 4),
      tts: props.tracks.tts.slice(0, 4),
      music: props.tracks.music.slice(0, 4),
      podcast: props.tracks.podcast.slice(0, 4),
    });
  }, [props.tracks]);

  useEffect(() => {
    if (props.auth.isAuthenticated === true && props.auth.user !== null) {
      dispatch(getBrandingDataFromServer({ user_id: props.auth.user.id }));
    }
  }, [props.auth.user]);

  useEffect(() => {
    props.fetchTracks();
  }, []);

  useEffect(() => {
    if (brandingData.brandingData){
      setBrandingName(
          brandingData.brandingdata.app_name
      )
    }
  }, [brandingData]);

  const handlePlayVideo = () => {
    setShow(true);
  };
  const handleCloseVideo = () => {
    setShow(false);
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>Sonority | Dashboard </title>
      </Helmet>
      <section className="sec-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <div className="sec-1-content text-white">
                <h1>
                  WELCOME{" "}
                  <span className="d-lg-block d-md-block  css-typing">
                    TO {brandingName !== "" ? brandingName : "SONORITY"}
                  </span>
                </h1>
                <p className="mt-4">
                  {brandingName !== "" ? brandingName : "Sonority"} turns your
                  text into a high-quality, human-sounding voiceover, gives you
                  1000s of unique audio tracks, and converts your audio content
                  into fully-customizable videos–all on complete autopilot.
                </p>
                <div className="demo-video mt-5">
                  <a href="#" className="btn-change8" onClick={handlePlayVideo}>
                    <span>
                      <i className="fas fa-caret-right" />
                    </span>{" "}
                    Watch Video
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-1 ">
              {props.auth.user === null ? (
                ""
              ) : props.auth.user.is_client_account === "0" ? (
                <div className="icon-content  text-white">
                  <DashCount
                    img={icon1}
                    name="Voice Track"
                    href="voice-track"
                    arrowiconimg={ArrowIcon}
                    x
                  />
                  {/* <DashCount
                    img={icon2}
                    name="Music Track"
                    href="music-track"
                    arrowiconimg={ArrowIcon}
                  /> */}
                  <DashCount
                    img={icon3}
                    name="Audio Track"
                    href="audio-track"
                    arrowiconimg={ArrowIcon}
                  />
                  <DashCount
                    img={icon4}
                    name="WaveVideo"
                    href="wave-video"
                    arrowiconimg={ArrowIcon}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="sec-2">
        <div className="container">
          <div className="row mt-5">
            {/*<h5 className="text-white">Voice Tracks</h5>*/}
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Voice <span>Tracks</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.tts.length > 0 ? (
              tracks.tts.map((item) => <TrackFile item={item} type="Voice" />)
            ) : (
              <p className="text-center text-white">
                No tracks found in voice track
              </p>
            )}
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Music <span>Tracks</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.music.length > 0 ? (
              tracks.music.map((item) => <TrackFile item={item} type="Music" />)
            ) : (
              <p className="text-center text-white">
                No tracks found in music track
              </p>
            )}
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Audio <span>Tracks</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.merge.length > 0 ? (
              tracks.merge.map((item, index) => (
                <TrackFile key={index} item={item} type="Audio" />
              ))
            ) : (
              <p className="text-center text-white">
                No tracks found in audio track
              </p>
            )}
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Wave <span>Videos</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.podcast.length > 0 ? (
              tracks.podcast.map((item, index) => (
                <VideoFile key={index} item={item} type="Wave" />
              ))
            ) : (
              <p className="text-center text-white">
                No tracks found in audio track
              </p>
            )}
          </div>
        </div>
      </section>
      <FeaturesContent />

      <div
        className="modal fade show"
        style={{ display: show === true ? "block" : "none" }}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseVideo}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<iframe*/}
              {/*    src="https://player.vimeo.com/video/564028727?background=1&loop=1&byline=0&title=0"*/}
              {/*    frameBorder="0"*/}
              {/*    allowFullScreen*/}
              {/*    width="600px"*/}
              {/*> </iframe>*/}
              {/*<ReactPlayer url='https://vimeo.com/564028727' width='100%' height='100%' />*/}
              <div className="row">
                <div className="col-md-12">
                  {/*<ReactPlayer url='https://vimeo.com/564028727'  playing={show} width="100%"/>*/}

                  <div className="embed-responsive embed-responsive-1by1">
                    <iframe
                      width="560"
                      height="315"
                      src="https://player.vimeo.com/video/564028727?"
                      title="sonority video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
  brandingData: state.brandingdata,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
