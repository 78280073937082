import React, {useEffect, useState} from 'react'
import {removeLoader, setLoader} from "../../actions/loader";
import {updateEmail} from "../../actions/auth";
import {connect} from "react-redux";


function ChangeEmail(props) {

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState({
    currentEmail:"",
    newEmail:""
  })

  const [check, setCheck] = useState(false);
    const handleSubmit = async (e) => {
      e.preventDefault();
      setCheck(true);
      setLoader(true);
      const userInformation = {
        email: email.newEmail
      };
      await props.updateEmail(userInformation);
      setCheck(false);
      setLoader(false);
    }

    const handleChange = (e) => {
        setEmail({
          ...email,
          [e.target.name]:e.target.value
        })
    }

    useEffect(() => {
      setEmail({
        ...email,
        currentEmail: props.user.email
      })
    },[props.user.email])
    return (
        <>

               <div className="row">
      <div className="col-md-12">
        <div className="change-head text-white">
          <h3>Change Email</h3>
        </div>
      </div>
    </div>

    <div className="row mt-3">
      <div className="col-md-12">
        <form onSubmit={(e) => handleSubmit(e)}>
        <div className="change-box">
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="inputEmail4">Current Email Address</label>
                <input type="email" name="currentEmail" className="form-control" id="inputEmail4" onChange={(e) => handleChange(e)} placeholder="Enter Current Email Address" value={email.currentEmail}/>
              </div>
            </div>
            <div className="col-md-6"> 
              <div className="form-group">
                <label htmlFor="inputPassword4">New Email Address</label>
                <input type="email" name="newEmail" className="form-control" id="inputPassword4" onChange={(e) => handleChange(e)} placeholder="Enter New Email Address" value={email.newEmail}/>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="update-btn">
                 <button  className="form-control btn-change8" type="submit">{loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Update</button>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    removeLoader: () => dispatch(removeLoader()),
    updateEmail: (data) => dispatch((updateEmail(data)))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
