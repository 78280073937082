import React, {useState} from 'react';
import logo from "../../assets/images/logo.png";
import Alert from "../Alert/Alert";
import {Link, Redirect} from "react-router-dom";
import Footer from "../Footer/Footer";
import {removeLoader, setLoader} from "../../actions/loader";
import queryString from "query-string";
import {setAlert} from "../../actions/alert";
import {connect} from "react-redux";
import {reset_password} from "../../actions/auth";

const PasswordReset = (props) => {
    const [password, setPassword] = useState({
        password:"",
        confirm_password:""
    });
    const handleOnChange = (e) => {
        setPassword({...password,[e.target.name]:e.target.value })
    }

    const token = queryString.parse(props.location.search).token;
    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setLoader();
        if (password.password !== password.confirm_password) {
            props.removeLoader();
            props.setAlert("Password did not match", "danger");
        } else if (!token) {
            props.removeLoader();
            props.setAlert(
                "Password has not been changed go back to email click to the reset password",
                "danger"
            );
        } else {
            props.reset_password(password, token, props.history);
        }
    };

    if (props.isAuthenticated) {
        return <Redirect to="/dashboard" />;
    }
    return (
        <>
            <section className="password-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <div className="password-content text-center text-white">
                                <img src={logo} className="img-fluid" alt />
                                <p className="mt-4">Create Gorgeous Short-Videos for Social Media, Ads &amp; more using premium templates.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="password-reset text-white">

                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <h1>Reset your password</h1>
                                    <Alert authStyle={"authStyle"} />
                                    <div className="form-group">
                                        <label htmlFor="inputPassword4">New Password</label>
                                        <input type="password" name="password" className="form-control" id="inputPassword4" placeholder="Your New Password" onChange={(e) => handleOnChange(e)} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputPassword4">Confirm New Password</label>
                                        <input type="password" name="confirm_password" className="form-control" id="inputPassword4" placeholder="Confirm New Password" onChange={(e) => handleOnChange(e)} required/>
                                    </div>
                                    <div className="submit-btn mt-5 d-flex justify-content-center">
                                        <button type="submit">{props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : null} SUBMIT</button>
                                    </div>
                                    <div className="have-login mt-4 d-flex justify-content-center">
                                        <Link to="login">Have Login ?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        reset_password: (password,token,history) => dispatch(reset_password(password,token,history)),
        removeLoader: () => dispatch(removeLoader()),
        setAlert:(message,type) => dispatch(setAlert(message,type))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
