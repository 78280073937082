import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { baseURL, config } from "../../globals/global";
import SpeakerHead from "./SpeakerHead";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import TrackFile from "../MyTracks/TrackFile";
import { removeLoader, setLoader } from "../../actions/loader";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert/Alert";
import { Helmet } from "react-helmet";
import * as queryString from "query-string";
const activate = [];
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function VoiceTrack(props) {
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("Choose file");
  const [search, setSearch] = useState({
    language: "American",
    gender: "female",
    tag: "",
  });
  const [speakers, setSpeakers] = useState([]);
  const [currentSpeaker, setCurrentSpeaker] = useState({
    id: 0,
    image: "",
    name: "",
    tags: "",
  });

  const [finalData, setFinalData] = useState({
    id: "",
    title: "",
    text: "",
    speaker: 0,
  });

  const [tracks, setTracks] = useState({
    tts: [],
  });

  const [translateLanguage, setTranslateLanguage] = useState({
    currentTranslatedLanguage: "",
    languages: [],
  });

  const [voice, setVoice] = useState(
    activate.indexOf(props.auth.user.email) === -1 ? 2 : 1
  );

  const voiceTrackId = queryString.parse(props.location.search).id;

  const handleTitleChange = (e) => {
    setFinalData({
      ...finalData,
      title: e.target.value,
    });
  };

  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    // searchSpeakerFun();
  };

  const searchSpeakerFun = () => {
    try {
      const endPoint = voice === 1 ? "fetch-speakers" : "fetch-speakers-2";

      axios
        .post(`${baseURL + endPoint}`, search, config)
        .then((res) => {
          if (res.data.status === true) {
            setSpeakers(res.data.data);
            props.removeLoader();
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
            props.removeLoader();
          } else {
            props.removeLoader();
          }
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          props.removeLoader();
        });
    } catch (err) {
      console.log(err);
      props.removeLoader();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoader();
    searchSpeakerFun();
  };

  useEffect(() => {
    searchSpeakerFun();
  }, []);

  const handleSpeakerClick = (speaker) => {
    console.log(speaker);
    setFinalData({
      ...finalData,
      speaker: speaker.id,
    });
    setCurrentSpeaker({ ...speaker });
  };

  const handleTextChange = (e) => {
    setFinalData({
      ...finalData,
      text: e.target.value.substring(0, 1000),
    });
  };

  const handleNextClick = (e) => {
    props.setLoader();
    try {
      const textScript = finalData.text;
      const body = {
        ...finalData,
        text: textScript.replace(/[\n]/g, ""),
      };
      if (body.title !== "") {
        if (body.text !== "") {
          if (body.speaker !== 0) {
            const endPoint =
              voice === 1 ? "create-campaign" : "create-campaign-2";
            axios
              .post(`${baseURL + endPoint}`, body, config)
              .then((res) => {
                if (res.data.status === true) {
                  props.fetchTracks();
                  props.setAlert("Voice Generated Successfully", "success");
                  props.removeLoader();
                } else if (res.data.status === false) {
                  props.setAlert(res.data.message, "danger");
                  props.removeLoader();
                } else {
                  props.setAlert("Something went wrong", "danger");
                  props.removeLoader();
                }
              })
              .catch((error) => {
                console.log(error, "Something went wrong");
                props.removeLoader();
              });
          } else {
            props.setAlert(
              "You have not selected any speaker. Please select a speaker one by clicking on the speaker list",
              "danger"
            );
            props.removeLoader();
          }
        } else {
          props.setAlert(
            "Please enter some script to generate voice",
            "danger"
          );
          props.removeLoader();
        }
      } else {
        props.setAlert("Please give a title to your voice", "danger");
        props.removeLoader();
      }
    } catch (err) {
      console.log(err);
      props.removeLoader();
    }
  };

  useEffect(() => {
    setTracks({
      tts: props.tracks.tts,
    });
  }, [JSON.stringify(props.tracks.tts)]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}fetch-translate-languages`, config)
        .then((res) => {
          if (res.data.status === true) {
            setTranslateLanguage({
              ...translateLanguage,
              languages: res.data.data.languages,
            });
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
            props.removeLoader();
          } else {
            props.setAlert("Something went wrong", "danger");
            props.removeLoader();
          }
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          props.removeLoader();
        });
    } catch (err) {
      console.log(err);
    }
    props.fetchTracks();
  }, []);

  useEffect(() => {
    if (tracks.tts.length > 0) {
      const rowIdDetails = tracks.tts.find((item) => item.id === voiceTrackId);
      if (rowIdDetails !== undefined) {
        setCurrentSpeaker({
          currentSpeaker,
          id: rowIdDetails.speaker.id,
          name: rowIdDetails.speaker.name,
          image: rowIdDetails.speaker.image,
          tags: rowIdDetails.speaker.tags,
        });

        setFinalData({
          ...finalData,
          title: rowIdDetails.title,
          text: rowIdDetails.text,
          speaker: rowIdDetails.speaker.id,
          id: rowIdDetails.id,
        });
      }
    }
    return () => {};
  }, [voiceTrackId, tracks.tts.length]);

  // Handle url change
  const handleUrlChange = (e) => {
    const url = e.target.value;
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    if (extension === "txt") {
      // the URL looks like a valid URL load-from-url
      try {
        axios
          .post(`${baseURL}load-from-url`, { url: url }, config)
          .then((res) => {
            if (res.data.status === true) {
              setFinalData({
                ...finalData,
                text: res.data.text.substring(0, 1000),
              });
            } else if (res.data.status === false) {
              props.setAlert(res.data.message, "danger");
              props.removeLoader();
            } else {
              props.setAlert("Something went wrong", "danger");
              props.removeLoader();
            }
          })
          .catch((error) => {
            console.log(error, "Something went wrong");
            props.removeLoader();
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      props.setAlert("Please give a valid txt url", "danger");
    }
  };

  // handle txt file upload
  const handleTextFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");

    setUploadedFileName(name);

    const ext = name.substring(lastDot + 1);
    if (ext === "txt") {
      const formData = new FormData();
      formData.append("file", selectedFile);
      axios
        .post(`${baseURL}upload-text`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setFinalData({
              ...finalData,
              text: res.data.text.substring(0, 1000),
            });
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong", "danger");
          }
        })
        .catch((error) => console.log(error));
    } else {
      props.setAlert("Please upload a valid txt file", "danger");
    }
  };

  const handleTranslateLanguageChange = (e) => {
    setTranslateLanguage({
      ...translateLanguage,
      currentTranslatedLanguage: e.target.value,
    });
  };

  const handleTranslateLanguage = () => {
    setLoading(true);
    try {
      axios
        .post(
          `${baseURL}translate`,
          {
            text: finalData.text,
            language_id: translateLanguage.currentTranslatedLanguage,
          },
          config
        )
        .then((res) => {
          if (res.data.status === true) {
            setLoading(false);
            setFinalData({
              ...finalData,
              text: res.data.text.substring(0, 1000),
            });
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
            props.removeLoader();
          } else {
            props.setAlert("Something went wrong", "danger");
            props.removeLoader();
          }
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          props.removeLoader();
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>Sonority | Voice Track </title>
      </Helmet>
      <section className="project-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/*<div className="alert alert-warning">*/}
              {/*    Sonority is currently experience high level of DDOS. Our voice rendering services might be effected. We apologies for the inconvenience cause and will back up soon.*/}
              {/*</div>*/}
              <div className="project-box text-white">
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Give a voice name
                  </label>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Give a voice name"
                    value={finalData.title}
                    onChange={(e) => handleTitleChange(e)}
                  />
                </div>
                <Alert />
                <div className="row mt-5">
                  <h4 className="text-white">Search your speaker</h4>
                  <form onSubmit={(e) => handleSubmit(e)} method="POST">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Language
                          </label>
                          <select
                            className="form-control"
                            name="language"
                            value={search.language}
                            onChange={(e) => handleChange(e)}
                            style={{ backgroundColor: "#683097" }}
                          >
                            <option
                              value="English Premium"
                              className="sc-fzoLag BNtsP"
                            >
                              English Premium
                            </option>
                            <option
                              value="American"
                              className="sc-fzoLag BNtsP"
                            >
                              American
                            </option>
                            <option
                              value="Australian"
                              className="sc-fzoLag BNtsP"
                            >
                              Australian
                            </option>
                            <option value="British" className="sc-fzoLag BNtsP">
                              British
                            </option>
                            <option
                              value="Indian American"
                              className="sc-fzoLag BNtsP"
                            >
                              Indian American
                            </option>
                            {/*<option value="Indonesian"*/}
                            {/*        className="sc-fzoLag BNtsP">Indonesian*/}
                            {/*</option>*/}
                            <option value="French" className="sc-fzoLag BNtsP">
                              French
                            </option>
                            {/*<option value="South African"*/}
                            {/*        className="sc-fzoLag BNtsP">South African*/}
                            {/*</option>*/}
                            <option value="Arabic" className="sc-fzoLag BNtsP">
                              Arabic
                            </option>
                            <option
                              value="Portuguese(BR)"
                              className="sc-fzoLag BNtsP"
                            >
                              Brazilian
                            </option>
                            <option
                              value="French Canadien"
                              className="sc-fzoLag BNtsP"
                            >
                              French Canadien
                            </option>
                            <option value="Chinese" className="sc-fzoLag BNtsP">
                              Chinese
                            </option>
                            {/*<option value="Greek" className="sc-fzoLag BNtsP">Greek*/}
                            {/*</option>*/}
                            {/*<option value="Czech" className="sc-fzoLag BNtsP">Czech*/}
                            {/*</option>*/}
                            <option value="Hindi" className="sc-fzoLag BNtsP">
                              Hindi
                            </option>
                            {/*<option value="Danish" className="sc-fzoLag BNtsP">Danish*/}
                            {/*</option>*/}
                            <option value="Dutch" className="sc-fzoLag BNtsP">
                              Dutch
                            </option>
                            {/*<option value="Filipino"*/}
                            {/*        className="sc-fzoLag BNtsP">Filipino*/}
                            {/*</option>*/}
                            {/*<option value="Finnish"*/}
                            {/*        className="sc-fzoLag BNtsP">Finnish*/}
                            {/*</option>*/}
                            {/*<option value="Hungarian"*/}
                            {/*        className="sc-fzoLag BNtsP">Hungarian*/}
                            {/*</option>*/}
                            <option value="German" className="sc-fzoLag BNtsP">
                              German
                            </option>
                            <option value="Italian" className="sc-fzoLag BNtsP">
                              Italian
                            </option>
                            <option
                              value="Japanese"
                              className="sc-fzoLag BNtsP"
                            >
                              Japanese
                            </option>
                            <option value="Korean" className="sc-fzoLag BNtsP">
                              Korean
                            </option>

                            <option value="Mexican" className="sc-fzoLag BNtsP">
                              Mexican
                            </option>
                            <option value="Korean" className="sc-fzoLag BNtsP">
                              Korean
                            </option>
                            <option
                              value="Norwegian"
                              className="sc-fzoLag BNtsP"
                            >
                              Norwegian
                            </option>
                            <option value="Polish" className="sc-fzoLag BNtsP">
                              Polish
                            </option>
                            <option
                              value="Portuguese(PT)"
                              className="sc-fzoLag BNtsP"
                            >
                              Portuguese
                            </option>
                            <option value="Russian" className="sc-fzoLag BNtsP">
                              Russian
                            </option>
                            {/*<option value="Slovak" className="sc-fzoLag BNtsP">Slovak*/}
                            {/*</option>*/}
                            <option value="Swedish" className="sc-fzoLag BNtsP">
                              Swedish
                            </option>
                            <option value="Spanish" className="sc-fzoLag BNtsP">
                              Spanish
                            </option>
                            {/*<option value="Taiwanese"*/}
                            {/*        className="sc-fzoLag BNtsP">Taiwanese*/}
                            {/*</option>*/}
                            <option value="Turkish" className="sc-fzoLag BNtsP">
                              Turkish
                            </option>
                            {/*<option value="Ukrainian"*/}
                            {/*        className="sc-fzoLag BNtsP">Ukrainian*/}
                            {/*</option>*/}
                            <option
                              value="Vietnamese"
                              className="sc-fzoLag BNtsP"
                            >
                              Vietnamese
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Gender</label>
                          <select
                            style={{ backgroundColor: "#683097" }}
                            className="form-control"
                            name="gender"
                            value={search.gender}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Tags</label>
                          <input
                            type="text"
                            className="form-control"
                            name="tag"
                            placeholder="comma separated tags"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mt-4">
                          <button
                            type="submit"
                            style={{ marginTop: "10px" }}
                            className="btn-change8 float-right"
                          >
                            {" "}
                            {props.loader === true ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row mt-5">
                  <h5 className="text-white">
                    Your Speakers ({speakers.length})
                  </h5>
                  <Carousel responsive={responsive}>
                    {speakers.length > 0 ? (
                      speakers.map((item) => (
                        <SpeakerHead
                          key={item.id}
                          click={handleSpeakerClick}
                          speaker={item}
                        />
                      ))
                    ) : (
                      <p className="text-white text-center">
                        No speaker found with that filter
                      </p>
                    )}
                  </Carousel>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="review-box">
                      <div className="row">
                        <div className="col-md-2">
                          {currentSpeaker.image !== undefined ? (
                            <div className="client-img text-center">
                              <img
                                src={currentSpeaker.image}
                                alt=""
                                className="img-fluid width80"
                              />
                              <h6 className="mt-3">{currentSpeaker.name}</h6>
                              <p className="m-0">
                                {currentSpeaker.tags
                                  .split(",")
                                  .map((item) => "#" + item + ", ")}
                              </p>
                              {currentSpeaker.is_ssml === "1" ? (
                                <p>
                                  To add pause break, use below tag in your
                                  script <span>&lt;pause time="500" /&gt;</span>{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 ">
                          <div className="client-content  reviewcolor ">
                            {/* <p>it's highy recommended to render as many characters as possible in a single audio file. This will help you to render less audio having better sound-voice quality.</p> */}
                            <div className="form-group">
                              <textarea
                                name="text"
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                placeholder="Enter script to generate voice"
                                rows={10}
                                value={finalData.text}
                                onChange={(e) => handleTextChange(e)}
                              />
                            </div>
                          </div>

                          <div className="pauseRender mt-3">
                            <div className="row mt-4">
                              <div className="col-md-6">
                                {/*<p className="text-white">Count: {finalData.text.length} / 500</p>*/}
                              </div>
                              <div className="col-md-6">
                                <div className="next-btn">
                                  <button
                                    type="button"
                                    className="btn-change8 float-right"
                                    onClick={(e) => handleNextClick(e)}
                                  >
                                    {" "}
                                    {props.loader === true ? (
                                      <i className="fa fa-spinner fa-spin mr-2" />
                                    ) : null}{" "}
                                    Generate Voice
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 ">
                          <div className="load-text">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Load Text From A URL
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter A .txt Extension URL"
                                onChange={(e) => handleUrlChange(e)}
                              />
                            </div>

                            <h5 className="text-center pt-3 d-flex align-items-center justify-content-center">
                              <span className="or-border"> </span> &nbsp; OR
                              &nbsp; <span className="or-border"> </span>
                            </h5>

                            <div className="input-group mt-4 mb-3">
                              <div className="custom-file browse-uplaod">
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  className="custom-file-input coustom-url"
                                  id="inputGroupFile01"
                                  onChange={(e) => handleTextFileUpload(e)}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="inputGroupFile01"
                                >
                                  {uploadedFileName}
                                </label>
                              </div>
                            </div>

                            <div className="Diffrent-language">
                              <label>
                                Translate Text To A Different Language
                              </label>
                              <div className="input-group">
                                <select
                                  onChange={(e) =>
                                    handleTranslateLanguageChange(e)
                                  }
                                  className="custom-select"
                                  id="inputGroupSelect04"
                                  value={
                                    translateLanguage.currentTranslatedLanguage
                                  }
                                >
                                  <option defaultValue>
                                    -Select a Language-
                                  </option>
                                  {translateLanguage.languages.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                                </select>
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-outline-secondary go"
                                    type="button"
                                    onClick={handleTranslateLanguage}
                                  >
                                    {loading === true ? (
                                      <div class="d-flex justify-content-center">
                                        <div
                                          class="spinner-border"
                                          role="status"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                          }}
                                        >
                                          <span class="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      "GO"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-2">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  Your Voice <span>Tracks</span>
                </h3>
                <Link to="my-tracks">
                  <h6>
                    VIEW ALL{" "}
                    <span>
                      {" "}
                      <i className="far fa-arrow-alt-circle-right" />{" "}
                    </span>
                  </h6>
                </Link>
              </div>
            </div>
            {tracks.tts.length > 0 ? (
              tracks.tts.map((item) => (
                <TrackFile key={item.id} item={item} type="Voice" />
              ))
            ) : (
              <p className="text-center text-white">
                No tracks found in voice track
              </p>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VoiceTrack);
