import { SET_LOADER, REMOVE_LOADER, SET_VERIFY_LOADER } from "./Types";

export const setLoader = () => (dispatch) => {
    dispatch({
        type: SET_LOADER,
    });
};

export const setVerifyLoader = () => (dispatch) => {
    dispatch({
        type: SET_VERIFY_LOADER,
    });
};

export const removeLoader = () => (dispatch) => {
    dispatch({
        type: REMOVE_LOADER,
    });
};
