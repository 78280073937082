import React, {useState} from 'react';
import logo from "../../assets/images/logo.png";
import Footer from "../Footer/Footer";
import {Link, Redirect} from "react-router-dom";
import {removeLoader, setLoader} from "../../actions/loader";
import {forgot_password} from "../../actions/auth";
import {connect} from "react-redux";
import {removeAlert} from "../../actions/alert";
import Alert from "../Alert/Alert";
import {Helmet} from "react-helmet";

const ForgetPassword = (props) => {
    const [email, setEmail] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        props.setLoader();
        props.forget_password(email);
    };
    const handleOnChange = (e) => {
        setEmail(e.target.value)
    }
    if (props.isAuthenticated) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            <Helmet>
                <title>Sonority | Forgot Password </title>
            </Helmet>
            <section className="password-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <div className="password-content text-center text-white">
                                <img src={logo} className="img-fluid" alt />
                                <p className="mt-4">Create Gorgeous Short-Videos for Social Media, Ads &amp; more using premium templates.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="password-reset text-white">

                                <form onSubmit={(e) => sendEmail(e)}>
                                    <h1>Enter Your Email Address</h1>
                                    <Alert authStyle={"authStyle"} />
                                    <div className="form-group">
                                        <label htmlFor="inputPassword4">Email Address</label>
                                        <input type="email" name="email" className="form-control" id="inputPassword4" placeholder="Your Email Address" onChange={(e) => handleOnChange(e)} required/>
                                    </div>
                                    <div className="submit-btn mt-5 d-flex justify-content-center">
                                        <button type="submit"> {props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : null} SUBMIT</button>
                                    </div>
                                    <div className="have-login mt-4 d-flex justify-content-center">
                                        <Link to="login">Have Login ?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        forget_password: (email) => dispatch(forgot_password(email)),
        removeLoader:() => dispatch(removeLoader()),
        removeAlert: () => dispatch(removeAlert())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
