import { SET_TRAINING } from "../actions/Types";

const initialState = {
  categories: [],
  articles: [],
  videos: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TRAINING:
      return {
        ...state,
        categories: payload.categories,
        articles: payload.articles,
        videos: payload.videos,
      };
    default:
      return state;
  }
}
