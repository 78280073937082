import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { setLoader } from "../../actions/loader";
import { connect } from "react-redux";
import TrackFile from "./TrackFile";
import { fetchTracks } from "../../actions/tracks";
import { Helmet } from "react-helmet";
import VideoFile from "./VideoFile";
function MyTracks(props) {
  const [tracks, setTracks] = useState({
    merge: [],
    tts: [],
    music: [],
    podcast: [],
  });

  useEffect(() => {
    setTracks({
      merge: props.tracks.merge,
      tts: props.tracks.tts,
      music: props.tracks.music,
      podcast: props.tracks.podcast,
    });
  }, [props.tracks]);

  useEffect(() => {
    props.fetchTracks();
  }, []);

  return (
    <>
      <Header />
      <Helmet>
        <title>Sonority | MyTracks </title>
      </Helmet>
      <section className="sec-2 mytracksec-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  My <span>Tracks</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <h5 className="text-white">Voice Tracks</h5>
            {tracks.tts.length > 0 ? (
              tracks.tts.map((item) => <TrackFile item={item} type="Voice" />)
            ) : (
              <p className="text-center text-white">
                No tracks found in voice track
              </p>
            )}
          </div>
          <div className="row mt-5">
            <h5 className="text-white">Music Tracks</h5>
            {tracks.music.length > 0 ? (
              tracks.music.map((item) => <TrackFile item={item} type="Music" />)
            ) : (
              <p className="text-center text-white">
                No tracks found in music track
              </p>
            )}
          </div>

          <div className="row mt-5">
            <h5 className="text-white">Audio Tracks</h5>
            {tracks.merge.length > 0 ? (
              tracks.merge.map((item) => <TrackFile item={item} type="Audio" />)
            ) : (
              <p className="text-center text-white">
                No tracks found in audio track
              </p>
            )}
          </div>

          <div className="row mt-5">
            <h5 className="text-white">Wave Videos</h5>
            {tracks.podcast.length > 0 ? (
              tracks.podcast.map((item) => (
                <VideoFile item={item} type="Wave" />
              ))
            ) : (
              <p className="text-center text-white">
                No tracks found in audio track
              </p>
            )}
          </div>
          {/*<div className="row mt-5">*/}
          {/*    <h5 className="text-white">Wave Video</h5>*/}
          {/*    {*/}
          {/*        tracks.wave.length > 0 ? tracks.wave.map(item => <TrackFile item={item} type="wave" />): <p>No video found in wave video</p>*/}
          {/*    }*/}
          {/*</div>*/}
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyTracks);
