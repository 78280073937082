import React, {useState} from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../globals/global";
import {setAlert} from "../../actions/alert";
import {connect} from "react-redux";
import Alert from "../Alert/Alert";

const ResellerAccount = (props) => {

    const [loader, setLoader] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [clientID,setClientID] = useState({id: null});
    const [button, setButton] = useState('Delete');

    const [clientDetails, setClientDetails] = useState({
        name: "",
        password: "",
        id: ""
    });

    const onButtonClick = (e,id) => {
        e.preventDefault();
        setShowDelete(true);

        setClientID({id: id});
    }

    const onConfirm = () =>{
        setButton('Please wait...');
        let config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": props.auth.token
            },
        }

        try{
            axios.post(`${baseURL}delete-reseller-accounts`, clientID,config).then((res)=>{
                if(res.data.status === true)
                {
                    props.fetchReseller();
                    props.setAlert(res.data.message,'success');
                }
                else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger');
                }
                else
                {
                    props.setAlert('Something went wrong','danger');
                }
                setButton('Delete');
                setShowDelete(false);

            }).catch((error) => {
                console.log(error,'Something went wrong');
                setButton('Delete');
                setShowDelete(false);
            })
        }catch (error){
            console.log(error,'Something went wrong')
        }
    }

    const onCancel = () =>{
        setShowDelete(false);
    }

    const onInputChange = (e) => {
        setClientDetails({...clientDetails,[e.target.name]: e.target.value});
    }

    const onFormSubmit = (e,id,name) =>{
        e.preventDefault();
        setLoader(true);

        let data = {
            "id": id,
            "name" : clientDetails.name !== "" ? clientDetails.name : name,
            "password" : clientDetails.password
        }

        let config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": props.auth.token
            },
        }

        try{
            axios.post(`${baseURL}edit-reseller-accounts`, data,config).then((res)=>{
                if(res.data.status === true)
                {
                    props.fetchReseller();
                    props.setAlert(res.data.message,'success');
                    setLoader(false);
                }
                else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger');
                    setLoader(false);
                }
                else
                {
                    props.setAlert('Something went wrong','danger');
                    setLoader(false);
                }
            }).catch((error) => {
                console.log(error,'Something went wrong')
                setLoader(false);
            })
        }catch (error){
            console.log(error,'Something went wrong')
            setLoader(false);
        }
    }

    return (
        <>
            <Alert/>
            <div className="row mt-5">
                <div className="col-md-12">
                    <h4 className="text-white sizeweight">Reseller Account Management</h4>
                    <div className="client-box mt-4">
                        <table className="table text-white table-hover table-responsive-lg">
                            <thead className="" style={{backgroundColor:'#542886' , borderBottom:'none'}}>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Created</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.accounts.length > 0 ?
                                    props.accounts.map((item,index)=>{
                                        return(
                                            <>
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.created}</td>
                                                    <td>
                                                        <a data-toggle="collapse" data-parent={`#client_${item.id}`} href={`#client_${item.id}`} aria-expanded="false">
                                                            <i style={{marginRight:'10px'}} className="far fa-edit"></i>
                                                        </a>
                                                        <a onClick={(e)=>onButtonClick(e,item.id)}><i className="far fa-trash-alt"></i></a>
                                                    </td>
                                                </tr>
                                                <tr className="hide-table-padding">
                                                    <td colSpan="12" style={{padding: "0px"}}>
                                                        <div id={`client_${item.id}`} className="p-3 collapse in"
                                                             style={{width: "100%"}}>
                                                            <div className="col-12">
                                                                <p className="text-white"></p>
                                                                <div className="mt-2 mb-0">
                                                                    <h6 className=" text-left mb-4 mt-3"
                                                                        style={{marginLeft: "-10px"}}>Edit Client Member</h6>
                                                                    <div>
                                                                        <form method="post" onSubmit={(e)=>onFormSubmit(e,item.id,item.name)}>
                                                                            <div
                                                                                className="row d-flex justify-content-center">
                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                    <div
                                                                                        className="form-group form-group-mb">
                                                                                        <label className="input-label-pos">Name</label>
                                                                                        <input className="form-control input-dark-accnt personal-info w-100"
                                                                                               type="text" name="name" defaultValue={item.name}
                                                                                               onChange={(e)=>onInputChange(e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                    <div className="form-group form-group-mb">
                                                                                        <label className="input-label-pos">
                                                                                            Email Address
                                                                                        </label>
                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                               type="email" name="email" placeholder="Email Address"
                                                                                               readOnly value={item.email} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                    <div className="form-group form-group-mb">
                                                                                        <label className="input-label-pos">
                                                                                            Password <small>(leave blank for same password)</small>
                                                                                        </label>
                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                               type="password" name="password" placeholder=""
                                                                                               defaultValue={''} onChange={(e)=>onInputChange(e)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                    <div
                                                                                        className="form-group form-group-mb">
                                                                                        <label
                                                                                            className="input-label-pos">Account
                                                                                            Type
                                                                                        </label>
                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                               type="text" name="Account Type" placeholder="Account Type"
                                                                                               readOnly value="Client Account" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col col-md-12 cancel-update text-center mt-5 mb-5">
                                                                                <a style={{display: "inline-block", marginRight: "10px"}} type="button" className="btn-change8"
                                                                                   data-toggle="collapse" data-parent={`accordion${item.id}`}
                                                                                   href={`#client_${item.id}`} aria-expanded="false"
                                                                                >Cancel</a>
                                                                                <button style={{display: "inline-block",textTransform: "capitalize"}} type="submit" className="btn-change8" disabled={loader}>
                                                                                    { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' }Update
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </>
                                        )
                                    })
                                : ''
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                You Want to Delete this Account
            </SweetAlert>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader
});
const mapDispatchToProps = (dispatch) => {
    return {
        setAlert: (message,type) => dispatch(setAlert(message,type))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ResellerAccount
);
