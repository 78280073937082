import React, {useState} from 'react'
import Footer from '../Footer/Footer'
import loginLogo from '../../assets/images/loginlogo.png'
import {Link, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {login} from "../../actions/auth";
import {removeLoader, setLoader} from "../../actions/loader";
import Alert from "../Alert/Alert";
import {Helmet} from "react-helmet";

function Login(props) {
    const [userLogin, setUserLogin] = useState({ email: "", password: "" });
    const onChange = (e) =>{
        setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
    }
    const onLogin = async (e) => {
        e.preventDefault();
        props.setLoader();
        props.login(userLogin.email, userLogin.password, props.history);
    };

    if (props.auth.isAuthenticated && !props.auth.loading) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            <Helmet>
                <title>Sonority | Login </title>
            </Helmet>
            <section className="login-sec">
                <div className="container">
                    <div className="login-box text-white">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="login-content text-center">
                                    <h3>Welcome Back!</h3>
                                    <p>Create Synthetic Voice, Music &amp; Audio Tracks For Your Videos!</p>
                                    <div className="login-logo mt-5">
                                        <img src={loginLogo} alt className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="login-account text-center">
                                    <h4>Login to Your Account Now</h4>
                                    <Alert authStyle={"authStyle"} />
                                    <form onSubmit={(e) => onLogin(e)}>
                                        <div className="input-group mt-4 mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="far fa-envelope" /></span>
                                            </div>
                                            <input type="email" name="email" className="form-control" placeholder="Enter Your E-mail" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => onChange(e)} required/>
                                        </div>
                                        <div className="input-group mt-4 mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock" /></span>
                                            </div>
                                            <input type="password" name="password" className="form-control" placeholder="Enter Your Password" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => onChange(e)} required />
                                        </div>
                                        <div className="sign-btn mt-5">
                                            <button  type="submit"> {props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : null} Sign in to Sonority</button>
                                        </div>
                                        <span className="d-block mt-4"><Link to="forget-password">Forgot password?</Link></span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        removeLoader:() => dispatch(removeLoader()),
        login: (name,email,history) => dispatch(login(name,email,history))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(Login)
);
