import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import wp from "../../assets/images/round-icon-wordpress.png";
import im from "../../assets/images/round-icon-import.png";
import dn from "../../assets/images/round-icon-download.png";
import cus from "../../assets/images/round-icon-customize.png";
import vid from "../../assets/images/round-icon-usage.png";
import Rebranding from "./Rebranding";

const Upgrades = () => {
  const firstCol = [
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx",
      value: "Social Media Calendar",
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip",
      value: "Social Media Video Posts",
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip",
      value: "Social Media Image Posts",
    },
  ];

  const secondCol = [
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx",
      value: "Social Media Captions",
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/3565TopQuotePics.zip",
      value: "Inspirational, Motivational Quotes Images",
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/180K-famous-quotations5RR.zip",
      value: "Library of Quotes for Social Media",
    },
  ];

  const firstCol1 = [
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip",
      value: 1,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip",
      value: 2,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack3.zip",
      value: 3,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip",
      value: 4,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip",
      value: 5,
    },
  ];

  const secondCol1 = [
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip",
      value: 6,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip",
      value: 7,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip",
      value: 8,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack9.zip",
      value: 9,
    },
    {
      src: "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip",
      value: 10,
    },
  ];

  return (
    <>
      <Header />
      <section className="upgrade-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="my-track text-white d-flex justify-content-between">
                <h3>
                  My <span>Upgrades</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="upgrade-menu mt-4 text-white">
                <div
                  className="nav flex-column "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    FB Templates
                  </a>
                  {/*<a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Udemy Style Website</a>*/}
                  {/*<a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">DFY Course</a>*/}
                  <a
                    className="nav-link"
                    id="v-pills-clientcontent-tab"
                    data-toggle="pill"
                    href="#v-pills-clientcontent"
                    role="tab"
                    aria-controls="v-pills-clientcontent"
                    aria-selected="false"
                  >
                    Client Contract
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-Reseller-tab"
                    data-toggle="pill"
                    href="#v-pills-Reseller"
                    role="tab"
                    aria-controls="v-pills-Reseller"
                    aria-selected="false"
                  >
                    Reseller License
                  </a>
                  {/*<a className="nav-link" id="v-pills-Web-tab" data-toggle="pill" href="#v-pills-Web" role="tab" aria-controls="v-pills-Web" aria-selected="false">Web Hosting</a>*/}
                  <a
                    className="nav-link"
                    id="v-pills-Video-tab"
                    data-toggle="pill"
                    href="#v-pills-Video"
                    role="tab"
                    aria-controls="v-pills-Video"
                    aria-selected="false"
                  >
                    Video Commercial
                  </a>
                  {/*<a className="nav-link" id="v-pills-Videop-tab" data-toggle="pill" href="#v-pills-Videop" role="tab" aria-controls="v-pills-Videop" aria-selected="false">Video Promos</a>*/}
                  <a
                    className="nav-link"
                    id="v-pills-dfy-tab"
                    data-toggle="pill"
                    href="#v-pills-dfy"
                    role="tab"
                    aria-controls="v-pills-dfy"
                    aria-selected="false"
                  >
                    DFY Lead Magnets
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-dfy-tab"
                    data-toggle="pill"
                    href="#v-pills-agency"
                    role="tab"
                    aria-controls="v-pills-dfy"
                    aria-selected="false"
                  >
                    Agency Website
                  </a>
                  {/*<a className="nav-link" id="v-pills-dfy-tab" data-toggle="pill" href="#v-pills-social" role="tab" aria-controls="v-pills-dfy" aria-selected="false">Social Media Assets</a>*/}
                  {/*<a className="nav-link" id="v-pills-dfy-tab" data-toggle="pill" href="#v-pills-dfyArt" role="tab" aria-controls="v-pills-dfy" aria-selected="false">DFY Articles</a>*/}
                  <a
                    className="nav-link"
                    id="v-pills-dfy-tab"
                    data-toggle="pill"
                    href="#v-pills-wl"
                    role="tab"
                    aria-controls="v-pills-dfy"
                    aria-selected="false"
                  >
                    WL Rebranding
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-dfy-tab"
                    data-toggle="pill"
                    href="#v-pills-finder"
                    role="tab"
                    aria-controls="v-pills-dfy"
                    aria-selected="false"
                  >
                    Buisness Finder
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="upgrade-content text-white">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>FB Ads Templates</h5> <hr />
                        <p className="text-white">
                          Download your Facebook Ads Templates here
                        </p>
                        <a
                          href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                          target="_blank"
                        >
                          <button className="btn-change8">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Video Flix</h5> <hr />
                        <p className="text-white">Download Video Flix here</p>
                        <a
                          href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                          target="_blank"
                        >
                          <button className="btn-change8">Download</button>
                        </a>
                        <br />
                        <br />
                        <p className="text-white">
                          Download Video Flix training here
                        </p>
                        <a
                          href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                          target="_blank"
                        >
                          <button className="btn-change8">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/*<div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">*/}
                  {/*    <div className="row">*/}
                  {/*        <div className="col-md-12 p-3">*/}
                  {/*            <h5>Udemy Style Website</h5> <hr />*/}
                  {/*            <p className="text-white">Download Video Flix here</p>*/}
                  {/*            <a href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip" target="_blank">*/}
                  {/*                <button className="btn-change8">Download</button>*/}
                  {/*            </a>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>DFY Course</h5> <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 1</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 2</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 3</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 4</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 5</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 6</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 7</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 8</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 9</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 10</p>
                            <a
                              href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 11</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 12</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 13</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 14</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 15</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 16</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 17</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 18</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 19</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-6">
                            <p className="text-white">Download DFY Course 20</p>
                            <a
                              href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-clientcontent"
                    role="tabpanel"
                    aria-labelledby="v-pills-clientcontent-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Client Contract</h5> <hr />
                        <div className="row">
                          <div className="col-md-12">
                            Download our "Client Contract" template. This is a
                            docx file, works best with MS Word. Edit the doc and
                            add your business name, address and other details.
                            Fill the empty blank space with your service, eg
                            Video Marketing, Local Reputation Management, Social
                            Media Marketing, Design & Creatives, Ads Management
                            and so on.
                          </div>
                          <div className="col-md-4 mt-3 text-center">
                            <p className="text-white">
                              Client Brief Pitch Template
                            </p>
                            <a
                              href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-4 mt-3 text-center ">
                            <p className="text-white">
                              Client Service Agreement
                            </p>
                            <a
                              href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>

                          <div className="col-md-4 mt-3 text-center ">
                            <p className="text-white">
                              Client Meeting Setup Template
                            </p>
                            <a
                              href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                              target="_blank"
                            >
                              <button className="btn-change8">Download</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-Reseller"
                    role="tabpanel"
                    aria-labelledby="v-pills-Reseller-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Reseller License</h5> <hr />
                        <p>
                          Go to JVZoo Dashboard and request your affiliate link.
                          Once you've requested, submit a ticket with your
                          VideoReel Business transaction ID, affiliate ID and
                          email address for our team to activate your reseller
                          license. Please allow upto 2 working days.
                        </p>
                        <p className="text-white">
                          Download your Reseller Licence here
                        </p>
                        <a
                          href="https://www.jvzoo.com/affiliate/affiliateinfo/index/363723"
                          target="_blank"
                          style={{ marginRight: "10px" }}
                        >
                          <button className="btn-change8">
                            Request Affiliate Link
                          </button>
                        </a>
                        <a href="https://support.vineasx.com/" target="_blank">
                          <button className="btn-change8">
                            Raise Support Ticket
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-Web"
                    role="tabpanel"
                    aria-labelledby="v-pills-Web-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Web Hosting</h5> <hr />
                        <p>
                          Please raise a support ticket with your VideoReel
                          purchase transaction ID, your domain name that you
                          currently own and wish to use with the hosting, email
                          address, your full name and a note about how you would
                          be using the webhosting for your business.
                        </p>
                        <a href="https://support.vineasx.com/" target="_blank">
                          <button className="btn-change8">
                            Raise a Ticket
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-Video"
                    role="tabpanel"
                    aria-labelledby="v-pills-Video-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Video Commercial</h5> <hr />
                        <p className="text-white">
                          6X Stunning & Compelling Video Commercials
                        </p>
                        <a
                          href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                          target="_blank"
                        >
                          <button className="btn-change8">
                            Download Videos
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-Videop"
                    role="tabpanel"
                    aria-labelledby="v-pills-Videop-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Video Promos</h5> <hr />
                        <p>
                          Open a ticket with the following details per video:
                        </p>
                        <ol>
                          <li>Your VideoReel credentials</li>
                          <li>Permission to access your account</li>
                          <li>
                            What is the purpose of this video that you wish to
                            create?
                          </li>
                          <li>Name of the template you wish to use</li>
                          <li>Number of the template you wish to use</li>
                          <li>Screenshot of the template you wish to use</li>
                          <li>
                            Content for the video. Please click on the EDIT
                            option for the template you wish to use and share
                            with us all the text content, logo, music file or
                            Text-to-speech content along with images/video clips
                            you wish to use in your videos.
                          </li>
                        </ol>
                        <a href="https://support.vineasx.com/" target="_blank">
                          <button className="btn-change8">
                            Raise a Ticket
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-dfy"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>DFY Lead Magnets</h5> <hr />
                        <p className="text-white">
                          Download your DFY Lead Magnets here
                        </p>
                        <a
                          href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                          target="_blank"
                        >
                          <button className="btn-change8">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-agency"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Agency Website</h5> <hr />
                      </div>
                      <div className="col-12">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row" style={{ minHeight: "22vh" }}>
                              <div className="col-2 d-flex align-items-center justify-content-center">
                                <img src={wp} alt="wordpress" />
                              </div>
                              <div className="col-10 d-flex align-items-center">
                                Fresh Install and configure wordpress at your
                                server. If you need help in installing and
                                configuring wordpress, Click here to find the
                                tutorial.
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div
                              className="row"
                              style={{
                                alignItems: "center",
                                minHeight: "22vh",
                              }}
                            >
                              <div className="col-2 d-flex align-items-center justify-content-center">
                                <img src={dn} alt="download" />
                              </div>
                              <div className="col-10 d-flex align-items-center flex-column">
                                <div>
                                  Once wordpress is installed at your server.
                                  Download the site content by clicking the
                                  "Download site button". This will download a
                                  wpress file which you will be needing to
                                  import in your wordpress site.
                                </div>

                                <button
                                  style={{ marginTop: "5px" }}
                                  className="btn-change8"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.location =
                                      "https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress";
                                  }}
                                >
                                  Download the site
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row" style={{ minHeight: "22vh" }}>
                              <div className="col-2 d-flex align-items-center justify-content-center">
                                <img src={im} alt="import" />
                              </div>
                              <div className="col-10 d-flex align-items-center">
                                Import the xml file in to your wordpress site.
                                If you need help importing the xml file in
                                wordpress, Click here to find the tutorial.
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row" style={{ minHeight: "22vh" }}>
                              <div className="col-2 d-flex align-items-center justify-content-center">
                                <img src={cus} alt="customize" />
                              </div>
                              <div className="col-10 d-flex align-items-center">
                                Customize the site as according to your need. If
                                you need help customizing the site. Click here
                                to find the tutorial
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Tutorial</h5> <hr />
                      </div>
                      <div
                        className="row bg-table justify-content-center mt-2"
                        style={{ marginLeft: "0" }}
                      >
                        <div className="col-6 mt-2">
                          <div className="row">
                            <div className="col-11">
                              <div className="card fb-card border-0">
                                <div className="card-body">
                                  <div
                                    className="row"
                                    style={{ minHeight: "15vh" }}
                                  >
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                      <img
                                        src={wp}
                                        alt="wordpress"
                                        style={{
                                          width: "3rem",
                                          height: "3rem",
                                        }}
                                      />
                                    </div>
                                    <div className="col-9 d-flex align-items-center">
                                      <a
                                        className="tutr"
                                        target="-blank"
                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                        style={{ color: "white" }}
                                      >
                                        Install Wordpress from cPanel
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0"></div>
                              </div>
                            </div>
                            <div className="col-11 mt-2">
                              <div className="card fb-card border-0">
                                <div className="card-body">
                                  <div
                                    className="row"
                                    style={{ minHeight: "15vh" }}
                                  >
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                      <img
                                        src={im}
                                        alt="import"
                                        style={{
                                          width: "3rem",
                                          height: "3rem",
                                        }}
                                      />
                                    </div>
                                    <div className="col-9 d-flex align-items-center">
                                      <a
                                        className="tutr"
                                        target="-blank"
                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                        style={{ color: "white" }}
                                      >
                                        Import SyVid Agency data
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0"></div>
                              </div>
                            </div>
                            <div className="col-11 mt-2">
                              <div className="card fb-card border-0">
                                <div className="card-body">
                                  <div
                                    className="row"
                                    style={{ minHeight: "15vh" }}
                                  >
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                      <img
                                        src={cus}
                                        alt="customize"
                                        style={{
                                          width: "3rem",
                                          height: "3rem",
                                        }}
                                      />
                                    </div>
                                    <div className="col-9 d-flex align-items-center">
                                      <a
                                        className="tutr"
                                        target="-blank"
                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                        style={{ color: "white" }}
                                      >
                                        Customize website
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0"></div>
                              </div>
                            </div>
                            <div className="col-11 mt-2">
                              <div className="card fb-card border-0">
                                <div className="card-body">
                                  <div
                                    className="row"
                                    style={{ minHeight: "15vh" }}
                                  >
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                      <img
                                        src={vid}
                                        alt="usage"
                                        style={{
                                          width: "3rem",
                                          height: "3rem",
                                        }}
                                      />
                                    </div>
                                    <div className="col-9 d-flex align-items-center">
                                      <a
                                        className="tutr"
                                        target="-blank"
                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                        style={{ color: "white" }}
                                      >
                                        Upload Video and Usage in Agency website
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mt-2">
                          <div className="card fb-card border-0">
                            <div
                              className="card-header text-center"
                              style={{ background: "#007bff" }}
                            >
                              <h6
                                className="text-white Account-Management-headings"
                                style={{
                                  fontSize: "18px !important",
                                }}
                              >
                                How to Install the Agency Website
                              </h6>
                            </div>
                            <div
                              className="card-body p-0"
                              // style={{ height: "19rem" }}
                            >
                              <div className="vid-wrapper">
                                <div
                                  className="plyr__video-embed"
                                  id="player"
                                  // data-plyr-provider="vimeo"
                                  // data-plyr-embed-id="512159766"
                                >
                                  <iframe
                                    src="https://player.vimeo.com/video/514577280?background=1&loop=1&byline=0&title=0"
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-social"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Social Media Assets</h5> <hr />
                        <p className="text-white">
                          Download your plug & play social media assets bellow
                        </p>
                      </div>
                      <div
                        className="row bg-table justify-content-center mt-2"
                        style={{ marginLeft: "0px" }}
                      >
                        <div className="col-6">
                          <div className="row">
                            {firstCol.map((value) => (
                              <div className="col-10 mt-3">
                                <button
                                  className="btn-change8"
                                  style={{ fontSize: "0.9rem" }}
                                  href="!#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.location = value.src;
                                  }}
                                >
                                  {value.value}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            {secondCol.map((value) => (
                              <div className="col-10 mt-3">
                                <button
                                  className="btn-change8"
                                  style={{ fontSize: "0.9rem" }}
                                  href="!#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.location = value.src;
                                  }}
                                >
                                  {value.value}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-dfyArt"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-12 p-3 ">
                        <h5>DFY Articles</h5> <hr />
                        <div className="row bg-table justify-content-center mt-2">
                          <div className="col-6">
                            <div className="row">
                              {firstCol1.map((value) => (
                                <div className="col-10 mt-3">
                                  <button
                                    className="btn-change8"
                                    style={{ fontSize: "0.9rem" }}
                                    href="!#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.location = value.src;
                                    }}
                                  >
                                    Article Pack {value.value}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              {secondCol1.map((value) => (
                                <div className="col-10 mt-3">
                                  <button
                                    className="btn-change8"
                                    style={{ fontSize: "0.9rem" }}
                                    href="!#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.location = value.src;
                                    }}
                                  >
                                    Article Pack {value.value}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-wl"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>WL Rebranding</h5> <hr />
                      </div>
                      <div className="col-md-12">
                        <Rebranding />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-finder"
                    role="tabpanel"
                    aria-labelledby="v-pills-dfy-tab"
                  >
                    <div className="row">
                      <div className="col-md-12 p-3">
                        <h5>Business Finder</h5> <hr />
                        <iframe
                          style={{ width: "100%", height: "90vh" }}
                          src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                        >
                          {" "}
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Upgrades;
