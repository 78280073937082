import {
    SET_TRAINING
} from './Types';
import axios from "axios";
import {baseURL, config} from "../globals/global";

export const addTraining = () => dispatch => {
    try {
        axios.get(`${baseURL}fetch-all-tutorials`, config).then(res => {
            if (res.data.status === true) {
                dispatch({type:SET_TRAINING,payload:res.data.data});
                
            } else {

            }
        }).catch(error => console.log(error));

    } catch (error) {
        console.log(error)
    }
}