import { FETCH_TRANSCRIBE } from "../actions/Types";

const initialState = {
    transcribe:[]
};

export default function (state = initialState, action) {
    const {type, payload } = action;
    switch (type) {
        case FETCH_TRANSCRIBE:
            return { ...state, transcribe: payload};
        default:
            return state;
    }
}
