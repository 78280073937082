import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import trainingImage from '../../assets/images/training.png'
import ImportantTopic from "./ImportantTopic";
import Popular from "./Popular";
import HelpSupport from "./HelpSupport";
import Footer from "../Footer/Footer";
import {Helmet} from "react-helmet";
import {removeLoader, setLoader} from "../../actions/loader";
import {connect} from "react-redux";
import {addTraining} from "../../actions/training";
const Training = (props) => {
    const [training, setTraining] = useState({
        categories:[],
        articles:[],
        videos:[]
    })
    useEffect(() => {
        setTraining({
            categories:props.tutorial.categories,
            articles:props.tutorial.articles,
            videos:props.tutorial.videos
        })
    },[JSON.stringify(props.tutorial)])

    useEffect(() => {
        props.addTraining();
    },[])
    return (
        <div>
            <Header />
            <Helmet>
                <title>Sonority | Help & Training </title>
            </Helmet>
            <ImportantTopic categories={training.categories}/>
            <Popular videos={training.videos}/>
            <HelpSupport/>
            <Footer/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
    tutorial: state.training
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        removeLoader:() => dispatch(removeLoader()),
        addTraining:() => dispatch(addTraining())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training);
