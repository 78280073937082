import React, { useState, useEffect } from "react";
import ChangeEmail from "./ChangeEmail";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Alert from "../Alert/Alert";
import { removeLoader, setLoader } from "../../actions/loader";
import { gdprRequest, updateEmail } from "../../actions/auth";
import { connect, useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { setAlert } from "../../actions/alert";
import axios from "axios";
import { getBrandingDataFromServer } from "../../actions/branding";
import { baseURL } from "../../globals/global";

const PrivacySettings = (props) => {
  const brandingData = useSelector((state) => state.brandingdata);
  const [disable, setDisable] = useState(false);
  const [remove, setRemove] = useState(false);
  const [brandingName, setBrandingName] = useState("");
  const [actionType, setActionType] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [showDownlaod, setShowDownload] = useState(false);
  const dispatch = useDispatch();
  const onConfirm = () => {
    if (actionType === "disable") {
      setDisable(true);

      props.gdprRequest("disable");
      setDisable(false);
    }
    if (actionType === "delete") {
      setRemove(true);
      props.gdprRequest("delete");
      setRemove(false);
    }
  };

  const onCancel = () => {
    setShowDelete(false);
    setShowDownload(false);
  };

  const handleClick = (type) => {
    //props.setLoader();

    setActionType(type);
    setShowDelete(true);

    //props.removeLoader();
  };

  const handleClickForDownload = () => {
    setShowDownload(true);
  };

  const onConfirm1 = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: props.auth.token,
      },
    };

    try {
      axios.get(`${baseURL}download-user-data`, config).then((res) => {
        if (res.data.status === true) {
          window.open(res.data.file);
        }
        console.log(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (brandingData.brandingdata.reseller) {
      setBrandingName(
        !brandingData.loading || brandingData.brandingdata.reseller
          ? brandingData.brandingdata.reseller.app_name
          : ""
      );
    }
  }, [brandingData]);

  return (
    <>
      <Header />
      <Alert />
      <section className="gdpr-sec">
        <div className="container">
          <ChangeEmail />
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="manage-account text-white">
                <h3>Manage Your Account Data</h3>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="manage-box text-white">
                <div className="download-data">
                  <p>
                    {brandingName !== "" ? brandingName : "Sonority"} stores
                    your private informations like your name, email, password.
                    It also stores your uploaded graphics such as images, videos
                    and your music files along with TTS ( Text to speech). If
                    you wish to download these data, you can do that "by
                    clicking" download your data.
                  </p>
                  <button onClick={(e) => handleClickForDownload()}>
                    {" "}
                    {props.loader === true ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Download My Data
                  </button>
                </div>
                <div className="stop-data mt-4">
                  <p>
                    If you wish to stop your private data from getting processed
                    on {brandingName !== "" ? brandingName : "Sonority"} you can
                    click the button to do so. Please note that doing this will
                    disable your account and you will not be able to use{" "}
                    {brandingName !== "" ? brandingName : "Sonority"} further.
                    In order to reactivate your account, you would have to
                    contact our support team at{" "}
                    <span className="supportcolor"> support@vineasx.com. </span>{" "}
                    <span className="pleasecolor">
                      Please Proceed With Caution.{" "}
                    </span>{" "}
                  </p>
                  <button onClick={(e) => handleClick("disable")}>
                    {disable === true
                      ? "Disabling..."
                      : "Stop Processing My Data"}
                  </button>
                </div>
                <div className="delete-data mt-4">
                  <p>
                    If you wish to delete all your private data, you can do so
                    by clicking this button. Please note that this will
                    permanently delete all the data of yours from our server.
                    This step is not reversible and you cannot use{" "}
                    {brandingName !== "" ? brandingName : "Sonority"} further.
                    You cannot undo this step from support also. So if you wish
                    to re-use {brandingName !== "" ? brandingName : "Sonority"}{" "}
                    again, then you will have to start all over again from
                    scratch.
                    <span>Please Proceed With Caution. </span>
                  </p>
                  <button onClick={(e) => handleClick("delete")}>
                    Delete My Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <SweetAlert
        warning
        showCancel
        confirmBtnText={
          actionType.charAt(0).toUpperCase() + actionType.slice(1)
        }
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={(e) => onConfirm(e)}
        onCancel={(e) => onCancel(e)}
        focusCancelBtn
        show={showDelete}
      >
        You Want to {actionType} this Account
      </SweetAlert>

      <SweetAlert
        warning
        showCancel
        confirmBtnText="Download My Data"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Download My Data"
        onConfirm={(e) => onConfirm1(e)}
        onCancel={(e) => onCancel(e)}
        focusCancelBtn
        show={showDownlaod}
      >
        {brandingName !== "" ? brandingName : "Sonority"} stores your private
        informations. If you wish to download these data, you can do that by
        clicking Download My Data button.
      </SweetAlert>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  loading: state.auth.loading,
  brandingData: state.brandingdata,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    removeLoader: () => dispatch(removeLoader()),
    gdprRequest: (type) => dispatch(gdprRequest(type)),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettings);
