import React from 'react'

function HelpSupport() {
    return (
        <>
           <section className="customer-support">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="customer-content text-white text-center">
          <h5>CUSTOMER SUPPORT</h5>
          <h2>Can't find your answer?</h2>
          <p>We’re here to help! From general communication to specific VideoReel platform questions, please reach out so we can assist.</p>
          <div className="content-btn mt-5">
            <a href="https://vineasx.helpscoutdocs.com/" target="_blank" className="btn-change7">Contact Sonority</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
 
        </>
    )
}

export default HelpSupport
