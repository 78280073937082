import React, {useEffect, useState} from 'react'
import Header from '../Header/Header'
import logo from '../../assets/images/logo.png'
import simple from '../../assets/images/simple.jpg'
import Footer from '../Footer/Footer'
import {removeLoader, setLoader} from "../../actions/loader";
import {addTraining} from "../../actions/training";
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import * as queryString from "query-string";

function Article(props) {
    const [training, setTraining] = useState({
        categories:[],
        articles:[],
        videos:[]
    })
    const categoryId = queryString.parse(props.location.search).cid;


    useEffect(() => {
        setTraining({
            categories:props.tutorial.categories,
            articles:props.tutorial.articles,
            videos:props.tutorial.videos
        })
    },[JSON.stringify(props.tutorial)])

    useEffect(() => {
        props.addTraining();
    },[])
    return (
        <>
            <Header/>
            <section className="supportartical">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-area">
                                <h2 className="tpc-lg-cap">Training & Tutorial</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-5">
                        <div className="col-md-4" style={{background:'linear-gradient(to bottom , #521d68  , #340b53)' , height:'100%', borderRadius:'8px' , boxShadow:'0 0 16px 0 #000', padding:'32px',}}>
                            <div className="logo-sonority py-4 d-flex justify-content-center">
                                <img src={logo} alt="logo" className="img-fluid"/>
                            </div>
                            <h5 className="mt-4 mb-4 text-white">POPULAR TOPICS</h5>
                            <div className="nav flex-column float ">
                                {training.categories.map(item =><NavLink to={"article?cid="+item.id} className="nav-link" >{item.name} </NavLink> )}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="tab-content articalbgcolor" id="v-pills-tabContent">
                                <div className="tab-pane fade show active">
                                    {training.articles.map(item => {
                                        if(item.category_id === categoryId){
                                            const data = JSON.parse(item.data);
                                            return data.map(d => {
                                                return (
                                                    <div className="art-tpc tpc-wrapper w-100">
                                                        <div className="img-wrapper mb-3">
                                                            <img className="img-fluid" src={d.feature_url} alt="Thumbnail" />
                                                        </div>
                                                        <div className="tpc-details">
                                                            <Link to={"article-details?id="+d.id+"&cid="+categoryId} >
                                                                <h5 className="tpc-title md-txt text-white article-title-hover" >{d.title}</h5>
                                                            </Link>
                                                            <div className="tpc-meta d-flex">
                                                                <p className="tpc-date mr-3 mb-0"><i className="fa fa-calendar-o" aria-hidden="true" /><span className="date ml-2 text-white">{d.created}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        else
                                            return null
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
    tutorial: state.training
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        removeLoader:() => dispatch(removeLoader()),
        addTraining:() => dispatch(addTraining())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Article);
