import React from 'react'

function FeatureImageRight(props) {
    return (
        <>
           <section className="sec-4">
  <div className="container">
    <div className="row">
      <div className="col-md-6 p-0">
        <div className="sec-4-img">
          <img src={props.bg3img} alt className="img-fluid" />
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <div className="sec-4-content px-lg-5 px-md-5 text-white">
          <span className="color text-uppercase">{props.title}</span>
          <h2>{props.heading} <span className="d-lg-block">{props.subheading}</span> </h2>
          <p>{props.para}</p>
        </div>
      </div>
    </div>
  </div>
</section>
 
        </>
    )
}

export default FeatureImageRight
