import React, {useState} from 'react';
import {removeLoader, setLoader} from "../../actions/loader";
import {updateInformation} from "../../actions/auth";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";

const UpdatePassword = (props) => {
    const [password, setPassword] = useState({
        password:"",
        newPassword:"",
        confirmNewPassword:""
    })
    const handleChange = (e) => {
        setPassword({
            ...password,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.newPassword !== password.confirmNewPassword) {
            props.setAlert("Password did not match", "danger");
        } else {
            props.setLoader();
            const userData = {
               password:password.password,
                new_password:password.newPassword,
                confirm_password:password.confirmNewPassword
            };
            props.updateInformation(userData);
            props.removeLoader();
            setPassword({
                password:"",
                newPassword:"",
                confirmNewPassword:""
            });

        }
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="infromation-page mt-5">
                    <h4 className="text-center text-white">Update Password</h4>
                    <form className="mt-5 text-white" onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="m-0" htmlFor="inputEmail4">Old Password</label>
                                    <input onChange={(e) => handleChange(e)} type="password" name="password" className="form-control" id="inputEmail4" placeholder="........." />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="m-0" htmlFor="inputEmail4">New Password</label>
                                    <input onChange={(e) => handleChange(e)} type="password" name="newPassword" className="form-control" id="inputEmail4" placeholder="........." />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group m-0">
                                    <label className="m-0" htmlFor="inputEmail4">Confirm Password</label>
                                    <input onChange={(e) => handleChange(e)} type="password" name="confirmNewPassword" className="form-control" id="inputEmail4" placeholder="........." />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <button className="form-control btn-change8" type="submit"> {props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : null} Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        updateInformation: (data) => dispatch((updateInformation(data))),
        setAlert:(message,type) => dispatch(setAlert(message,type)),
        removeLoader:() => dispatch(removeLoader())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
