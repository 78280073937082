import React, { useEffect, useState } from "react";

import playMusic from "../../assets/images/playMusic.png";
import pauseMusic from "../../assets/images/pauseMusic.png";
import loaderMusic from "../../assets/images/loaderMusic.gif";

import axios from "axios";
import { baseURL, config } from "../../globals/global";
import { setAlert } from "../../actions/alert";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeLoader, setLoader } from "../../actions/loader";
let audio = "";
const MusicPlayer = (props) => {
  const history = useHistory();
  const [response, setResponse] = useState({
    changed: false,
    url: "",
    isPlaying: "no",
  });

  const handleClickPause = () => {
    setResponse({
      ...response,
      isPlaying: "no",
    });
  };
  const handleRedoClick = () => {
    setResponse({
      ...response,
      isPlaying: "loading",
    });
  };
  const handleClickPlay = () => {
    if (response.changed === true) {
      setResponse({
        ...response,
        isPlaying: "loading",
      });
    } else {
      setResponse({
        ...response,
        isPlaying: "yes",
      });
    }
  };

  useEffect(() => {
    if (response.isPlaying === "no") {
      if (audio !== "") {
        audio.pause();
      }
    }
    if (response.isPlaying === "loading") {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = props.request;
      try {
        axios
          .post(`${baseURL}create-music`, body, config)
          .then((res) => {
            if (res.data.status === true) {
              setTimeout(() => {
                audio = new Audio(res.data.file);
                const audioPromise = audio.play();
                if (audioPromise !== undefined) {
                  audioPromise
                    .then(() => {
                      setResponse({
                        ...response,
                        url: res.data.file,
                        changed: false,
                        isPlaying: "yes",
                      });
                    })
                    .catch((error) => {
                      console.log(error, "Not able to play");
                    });
                }
              }, 7000);
            } else if (res.data.status === false) {
              props.setAlert(res.data.message, "danger");
              setResponse({
                ...response,
                isPlaying: "no",
              });
            }
          })
          .catch((error) => {});
      } catch (err) {
        console.log(err);
      }
    }

    if (response.isPlaying === "yes") {
      if (audio !== "") {
        audio.play();
      }
    }
    if (audio !== "") {
      audio.addEventListener("ended", () => {
        setResponse({
          ...response,
          isPlaying: "no",
        });
      });
    }
  }, [response.isPlaying]);

  useEffect(() => {
    setResponse({
      ...response,
      changed: true,
    });
  }, [props.request.duration, props.request.playlist]);

  const handleClickSave = () => {
    props.setLoader();
    const body = {
      ...props.request,
      audio_url: response.url,
    };
    try {
      axios
        .post(`${baseURL}save-music`, body, config)
        .then((res) => {
          if (res.data.status === true) {
            props.setAlert("Generated music has been saved", "success");
            history.push("/dashboard");
            props.setLoader(false);
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
            props.removeLoader();
          } else {
            props.setAlert("Something went wrong. Please try again", "danger");
            props.removeLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          props.removeLoader();
        });
    } catch (err) {
      console.log(err);
      props.removeLoader();
    }
  };

  return (
    <section className="fixed-bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="music-player text-center">
              {/*{response.isPlaying === 'yes' ? <img src={waveMusic} className="musicPlayerImage" alt="" /> :<img src={MusicPlayerImage} className="musicPlayerImage" alt="" />}*/}
              {/*<img src={MusicPlayerImage} className="musicPlayerImage" alt="" />*/}

              <div className="row">
                <div className="col-md-4 d-flex align-items-center">
                  <div className="start-song">
                    {/*<div className="input-group">*/}
                    {/*    <input type="text" placeholder="0:45" className="form-control" />*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-around">
                  <div
                    className="restart-icon d-flex text-white align-items-center"
                    onClick={() => handleRedoClick()}
                  >
                    <span style={{ cursor: "pointer" }}>
                      <i
                        className="fas fa-2x fa-redo-alt"
                        ata-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="Generate Another"
                      >
                        {" "}
                      </i>{" "}
                    </span>
                  </div>
                  <div className="play-push-btn">
                    {/*<button className="btns">*/}
                    {/*    <span className="play"> </span>*/}
                    {/*    <span className="small-box"> </span>*/}
                    {/*</button>*/}
                    {response.isPlaying === "yes" ? (
                      <img
                        src={pauseMusic}
                        alt="Pause Button"
                        className="musicPlayIcon"
                        onClick={() => handleClickPause()}
                      />
                    ) : response.isPlaying === "loading" ? (
                      <img
                        src={loaderMusic}
                        alt="Load Button"
                        className="musicPlayIcon"
                      />
                    ) : (
                      <img
                        src={playMusic}
                        alt="Play Button"
                        className="musicPlayIcon"
                        ata-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="Generate/Play"
                        onClick={() => handleClickPlay()}
                      />
                    )}
                  </div>
                  <div className="like-icon text-white d-flex align-items-center">
                    {/*<span style={{cursor:"pointer"}} onClick={() => handleClickSave()}><i className="fas fa-2x fa-heart"> </i></span>*/}
                    {response.url !== "" ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickSave()}
                      >
                        <button type="button" className="btn-change8">
                          {props.loader ? (
                            <i className="fa fa-spinner fa-spin text-white">
                              {" "}
                            </i>
                          ) : null}{" "}
                          Save Music{" "}
                        </button>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-center justify-content-end">
                  <div className="start-song sizewidth">
                    {/*<div className="input-group">*/}
                    {/*    <input type="text" placeholder="1:45" className="form-control w-25" />*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  loader: state.loader.loader,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    removeLoader: () => dispatch(removeLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MusicPlayer);
