import React, {useEffect, useState} from 'react'
import {removeLoader, setLoader} from "../../actions/loader";
import {connect} from "react-redux";
import AvatarUpdate from "./AvatarUpdate";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {updateInformation} from "../../actions/auth";
import Alert from "../Alert/Alert";
import UpdatePassword from "./UpdatePassword";
import {Helmet} from "react-helmet";
function MyAccount(props) {
    const [info, setInfo] = useState({
        name:"",
        email:""
    })
    const handleChange = (e) => {
        setInfo({
            ...info,
            name:e.target.value
        })
    }

    useEffect(() => {
        setInfo({
            name:props.user.name,
            email: props.user.email
        })
    },[props.user.name, props.user.email])

    const handleSubmit = (e) => {
        e.preventDefault();
        props.setLoader();
        const userInformation = {
            name: info.name
        };
        props.updateInformation(userInformation);
        props.removeLoader();
    }
    return (
        <>
            <Header />
            <Alert />
            <Helmet>
                <title>Sonority | My Account </title>
            </Helmet>
            <section className="personal-sec">
                <div className="container">
                    <AvatarUpdate />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="infromation-page mt-5">
                                <h4 className="text-center text-white">Personal Information</h4>
                                <form className="mt-5 text-white" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="m-0" htmlFor="inputEmail4">Name</label>
                                                <input type="text" name="name" className="form-control" id="inputEmail4" value={info.name} onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="m-0" htmlFor="inputEmail4">Email</label>
                                                <input type="email" name="email" className="form-control" id="inputEmail4" value={info.email} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center mt-4">

                                        <div className="col-md-4">

                                            {<button className="form-control btn-change8" type="submit" >{props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : null} Update</button>}

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <UpdatePassword />
                </div>
            </section>
            <Footer />
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.user,
    loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        removeLoader: () => dispatch(removeLoader()),
        updateInformation: (data) => dispatch((updateInformation(data)))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
