import React, { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import { baseURL, config } from "../../globals/global";
import WaveCard from "../../assets/images/playcard4.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { removeLoader, setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
import { fetchTracks } from "../../actions/tracks";
import { fetchTranscribe } from "../../actions/transcribe";
import { connect } from "react-redux";

const TranscribeTrack = (props) => {
  const [localValue, setLocaleValue] = useState({
    image: "",
    type: "",
    name: "",
    created: "",
    editPath: "",
  });
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLocaleValue({
      image: WaveCard,
      type: "Transcribe",
      name: "FileName",
      created: "",
      editPath: "",
    });

    return () => {
      setLocaleValue({
        image: "",
        type: "",
        name: "",
        created: "",
        audioFile: "",
        editPath: "",
      });
    };
  }, []);

  const handleDeleteClick = () => {
    deleteItem("delete-transcribe", props.item.id);
  };

  const deleteItem = (type, id) => {
    setLoader(true);
    try {
      axios
        .post(`${baseURL + type}`, { id: id }, config)
        .then((res) => {
          if (res.data.status === true) {
            props.setAlert(res.data.message, "success");
            props.fetchTranscribe();
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong", "danger");
          }
          setLoader(false);
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          setLoader(false);
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <Alert />
      <div className="sec-2-box">
        <img src={localValue.image} alt="musics" className="img-fluid" />
        <div className="musice-title">
          <span>{localValue.type} Track</span>

          <div className="edit-delete-icon">
            <ul className="list-inline d-flex">
              {props.item.status !== "0" ? (
                <li>
                  {localValue.editPath === null ? null : (
                    <Link to={`edit-transcribe?id=${props.item.id}`}>
                      <span className="pointer">
                        <i className="far fa-edit"> </i>
                      </span>
                    </Link>
                  )}
                </li>
              ) : (
                ""
              )}
              <li>
                {loader ? (
                  <span className="pointer">
                    <i className="fa fa-spinner fa-spin text-white"> </i>
                  </span>
                ) : (
                  <span className="pointer" onClick={() => handleDeleteClick()}>
                    <i className="far fa-trash-alt"> </i>
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="play-songname d-flex align-items-center justify-content-around">
          <div className="songname text-white ml-3">
            <span>{localValue.created}</span>
            <h6 className="text-white">{props.item.title}</h6>
          </div>
          <div className="download-icon">
            {props.type !== "Music" ? (
              props.item.text_file !== "" ? (
                <a
                  href={baseURL + "/download-file?url=" + props.item.text_file}
                >
                  <span className="ml-3 text-white download pointer">
                    <i className="fas fa-download"> </i>
                  </span>
                </a>
              ) : (
                <div class="text-center" style={{ marginLeft: "20px" }}>
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  transcribe: state.transcribe,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
    fetchTranscribe: () => dispatch(fetchTranscribe()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TranscribeTrack);
