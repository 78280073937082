import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import loader from "./loader";
import tracks from "./tracks";
import training from "./training";
import transcribe from "./transcribe";
import brandingdata from "./branding";

export default combineReducers({
  auth,
  alert,
  loader,
  tracks,
  training,
  transcribe,
  brandingdata,
});
