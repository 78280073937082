import React from 'react';

const TextObject = (props) => {
    return (
        <>
            <p className="text-center" style={{color:props.text.color,fontSize:props.text.fontSize}}>
                {props.text.text}
            </p>
        </>
    );
};

export default TextObject;
