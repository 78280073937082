import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { setLoader } from "../../actions/loader";
import { login, logout } from "../../actions/auth";
import { connect, useSelector } from "react-redux";

const Navbar = (props) => {
  const authUser = useSelector((state) => state.auth);
  const [memLevels, setMemlevels] = useState([]);

  useEffect(() => {
    if (authUser.user === null) {
    } else {
      setMemlevels(authUser.user.membership.split("__"));
    }
  }, [authUser.user]);

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link nav-link-ltr" to="/">
            DASHBOARD
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link nav-link-ltr" to="my-tracks">
            MY TRACKS
          </Link>
        </li>
        {authUser.user !== null && authUser.user.is_client_account === "1" ? (
          ""
        ) : (
          <li className="nav-item">
            <Link className="nav-link nav-link-ltr" to="upload-n-transcribe">
              UPLOAD-N-TRANSCRIBE
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link className="nav-link nav-link-ltr" to="/training">
            HELP
          </Link>
        </li>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>
              <i className="far fa-user" />
            </span>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {authUser.user === null ? (
              ""
            ) : authUser.user.is_client_account === "0" &&
              authUser.user.is_virtual_account === "0" ? (
              <>
                <Link className="dropdown-item" to="/profile">
                  <span className="mr-2">
                    <i className="fas fa-user" />
                  </span>{" "}
                  My Account
                </Link>
                <Link className="dropdown-item" to="/gdpr">
                  <span className="mr-2">
                    <i className="fas fa-key" />
                  </span>{" "}
                  Privacy Setting
                </Link>
                {memLevels.includes("commercial") &&
                memLevels.length == 1 ? null : (
                  <Link className="dropdown-item" to="/accounts">
                    <span className="mr-2">
                      <i className="fas fa-users" />
                    </span>{" "}
                    Account Management
                  </Link>
                )}
              </>
            ) : (
              ""
            )}

            {authUser.user === null ? (
              ""
            ) : authUser.user.is_client_account === "0" ? (
              <>
                {memLevels.includes("agency") ? (
                  <Link className="dropdown-item" to="/upgrade">
                    <span className="mr-2">
                      <i className="fas fa-plus-square" />
                    </span>{" "}
                    Upgrades
                  </Link>
                ) : (
                  ""
                )}

                {/* <Link className="dropdown-item" to="/reseller">
                  <span className="mr-2">
                    <i className="fas fa-users" />
                  </span>{" "}
                  Reseller Accounts
                </Link> */}
              </>
            ) : (
              ""
            )}

            {authUser.user === null ? (
              ""
            ) : authUser.user.is_reseller === "0" &&
              authUser.user.is_client_account === "0" &&
              authUser.user.is_virtual_account === "0" ? (
              <Link className="dropdown-item" to="/reseller">
                <span className="mr-2">
                  <i className="fas fa-users" />
                </span>{" "}
                Reseller Accounts
              </Link>
            ) : null}

            <Link className="dropdown-item" to="/training">
              <span className="mr-2">
                <i className="fas fa-headset" />
              </span>{" "}
              Help &amp; Support
            </Link>
            <Link className="dropdown-divider" />
            <Link
              className="dropdown-item"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                props.logout(props.auth.user.id);
                return <Redirect to="/" />;
              }}
            >
              <span className="mr-2">
                <i className="fas fa-sign-out-alt" />
              </span>{" "}
              Sign Out
            </Link>
          </div>
        </div>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    logout: (id) => dispatch(logout(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
