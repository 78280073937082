// export const baseURL = "https://backend.heysonority.com/"
export const baseURL = "https://regis12.amzitrage.com/"
export let frontURL = `${window.location.protocol}//${window.location.hostname === "localhost"? window.location.hostname+":3000": window.location.hostname}`

export const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

