import React, { useEffect, useState } from "react";
import VoiceCard from "../../assets/images/playcard.png";
import MusicCard from "../../assets/images/playcard2.png";
import AudioCard from "../../assets/images/playcard3.png";
import WaveCard from "../../assets/images/playcard4.png";
import { Link } from "react-router-dom";
import { baseURL, config } from "../../globals/global";
import axios from "axios";
import { removeLoader, setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";
import Alert from "../Alert/Alert";
let audio = "";
const TrackFile = (props) => {
  const [localValue, setLocaleValue] = useState({
    image: "",
    type: "",
    name: "",
    created: "",
    audioFile: "",
    editPath: "",
  });

  const [playingState, setPlayingState] = useState("no");

  const handleClickPlay = () => {
    setPlayingState("loading");
  };

  const handleClickPause = () => {
    setPlayingState("no");
  };

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLocaleValue({
      image:
        props.type === "Voice"
          ? VoiceCard
          : props.type === "Music"
          ? MusicCard
          : props.type === "Audio"
          ? AudioCard
          : props.type === "Wave"
          ? WaveCard
          : null,
      type: props.type,
      name:
        props.type === "Voice"
          ? props.item.title
          : props.type === "Music"
          ? props.item.title
          : props.type === "Audio"
          ? props.item.name
          : props.type === "Wave"
          ? props.item.name
          : null,
      created: props.item.created,
      audioFile: props.item.output_audio,
      output_video: props.item.video_output,
      editPath:
        props.type === "Voice"
          ? "voice-track?id=" + props.item.id
          : props.type === "Audio"
          ? "audio-track?id=" + props.item.id
          : null,
    });

    return () => {
      setLocaleValue({
        image: "",
        type: "",
        name: "",
        created: "",
        audioFile: "",
        output_video: "",
        editPath: "",
      });
    };
  }, []);

  useEffect(() => {
    if (playingState === "no") {
      if (audio !== "") {
        audio.pause();
      }
    }
    if (playingState === "yes") {
      if (audio !== "") {
        audio.play();
      }
    }
    if (playingState === "loading") {
      // setTimeout(() =>{
      if (audio !== "") {
        audio.pause();
      }
      audio = new Audio(localValue.audioFile);
      const audioPromise = audio.play();
      if (audioPromise !== undefined) {
        audioPromise
          .then(() => {
            setPlayingState("yes");
          })
          .catch((error) => console.log(error, "Error playing Audio"));
      }
      // },5000)
    }
    if (audio !== "") {
      audio.addEventListener("ended", () => {
        setPlayingState("no");
      });
    }
  });

  const handleDeleteClick = () => {
    if (props.type === "Voice") {
      deleteItem("delete-tts", props.item.id);
    }
    if (props.type === "Music") {
      deleteItem("delete-music", props.item.id);
    }
    if (props.type === "Audio") {
      deleteItem("delete-merge", props.item.id);
    }
  };

  const deleteItem = (type, id) => {
    setLoader(true);
    try {
      axios
        .post(`${baseURL + type}`, { id: id }, config)
        .then((res) => {
          if (res.data.status === true) {
            props.setAlert(res.data.message, "success");
            props.fetchTracks();
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong", "danger");
          }
          setLoader(false);
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          setLoader(false);
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <Alert />
      <div className="sec-2-box">
        <img src={localValue.image} alt="musics" className="img-fluid" />
        <div className="musice-title">
          <span>{localValue.type} Track</span>

          <div className="edit-delete-icon">
            <ul className="list-inline d-flex">
              <li>
                {localValue.editPath === null ? null : props.auth.user
                    .is_client_account !== "1" ? (
                  <Link to={localValue.editPath}>
                    <span className="pointer" tabIndex="">
                      <i className="far fa-edit"> </i>
                    </span>
                  </Link>
                ) : null}
              </li>
              <li>
                {loader ? (
                  <span className="pointer">
                    <i className="fa fa-spinner fa-spin text-white"> </i>
                  </span>
                ) : props.auth.user.is_client_account !== "1" ? (
                  <span
                    className="pointer"
                    tabIndex="0"
                    onClick={() => handleDeleteClick()}
                    onKeyDown={() => handleDeleteClick()}
                  >
                    <i className="far fa-trash-alt"> </i>
                  </span>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
        <div className="div" style={{ width: "100%" }}>
          <div className="play-songname perfect_align">
            {playingState === "yes" ? (
              <div
                className="play-box text-white pointer"
                onClick={() => handleClickPause()}
              >
                <span>
                  <i className="far fa-pause-circle" />
                </span>
              </div>
            ) : playingState === "loading" ? (
              <i className="fa fa-spinner fa-spin text-white f-25" />
            ) : (
              <div
                className="play-box text-white pointer"
                onClick={() => handleClickPlay()}
              >
                <span>
                  <i className="far fa-play-circle" />
                </span>
              </div>
            )}

            <div className="songname text-white ml-3">
              <span title={localValue.created}>{localValue.created}</span>
              <h6 title={localValue.name} className="text-white">
                {localValue.name}
              </h6>
            </div>
            <div className="download-icon">
              {props.type !== "Music" ? (
                <a
                  href={baseURL + "/download-file?url=" + localValue.audioFile}
                >
                  <span className="ml-3 text-white download pointer">
                    <i className="fas fa-download"> </i>
                  </span>
                </a>
              ) : (
                <a
                  href={
                    baseURL + "/download-file?url=" + localValue.output_video
                  }
                >
                  <span className="ml-3 text-white download pointer">
                    <i className="fas fa-download"> </i>
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrackFile);
