import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import {Helmet} from "react-helmet";
import Alert from "../Alert/Alert";
import Footer from "../Footer/Footer";
import upload from "../../assets/images/upload.png";
import axios from "axios";
import {baseURL, config} from "../../globals/global";
import {removeLoader, setLoader} from "../../actions/loader";
import {setAlert} from "../../actions/alert";
import {fetchTracks} from "../../actions/tracks";
import {connect} from "react-redux";
import TranscribeTrack from "./TranscribeTrack";
import {fetchTranscribe} from "../../actions/transcribe";

const UploadTranscribe = (props) => {

    const [transcribeData, setTranscribeData] = useState([]);
    const [checkUpload, setCheckUpload] = useState(false);
    const [tracks, setTracks] = useState({
        tts:[],
        audio:[],
        music:[]
    })
    const [progress,setProgress] = useState();
    const [languages, setLanguages] = useState([]);

    const [finalData, setFinalData] = useState({
        project_name: "",
        language_id:""
    })

    const [audio, setAudio] = useState({
        url:"",
        file:""
    })


    useEffect(() => {
        setTracks({
            audio:props.tracks.merge,
            tts:props.tracks.tts,
            music:props.tracks.music,
            wave:props.tracks.wave
        })
    },[props.tracks])


    useEffect(() => {
        props.fetchTranscribe();
        props.fetchTracks();

        setTranscribeData(props.transcribe.transcribe);
        console.log(transcribeData);
    },[])

    useEffect(()=>{
        setTranscribeData(props.transcribe.transcribe);
    },[props.transcribe.transcribe]);


    const handleUpload = (e) => {
        setCheckUpload(true);
        const selectedFile = e.target.files[0];
        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf('.');

        const ext = name.substring(lastDot + 1);
        if(ext === 'mp3' || ext === 'wav')
        {
            setAudio({
                url:"",
                file:selectedFile
            })
        }
        else
        {
            props.setAlert("Please upload a valid file",'danger')
        }
    }

    const handleOnChange = (e) => {
        const audioUrl = e.target.value;
        setAudio({
            file:"",
            url:audioUrl
        })
    }

    const clickTranscribeButton = () => {
        props.setLoader(true);
        try{

            let data;
            if(checkUpload){
                data = new FormData();
                data.append('file',audio.file);
                data.append('language_id', finalData.language_id);
            }else{
                data = {
                    "language_id" : finalData.language_id,
                    "project_name" : finalData.project_name,
                    "url" : audio.url
                }
            }

            axios.post(`${baseURL}speech-to-text`,data, config).then(res => {
                if(res.data.status === true)
                {
                    props.fetchTranscribe();
                    props.setAlert(res.data.message,'success');
                    props.removeLoader();
                } else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger');
                    props.removeLoader();
                }
                else
                {
                    props.setAlert("Something went wrong","danger");
                    props.removeLoader();
                }
            }).catch(error => console.log(error))
        }catch (error){
            props.removeLoader();
        }
    }

    const handleOnTranscribeLanguageChange = (e) => {
        console.log(e.target.value);
        setFinalData({
            ...finalData,
            language_id: e.target.value
        })
    }

    const checkTranscribeStatus = (job_id,interval) => {

        let data = {
            'job_id': job_id
        }

        axios.post(`${baseURL}fetch-job-status`,data,config).then(res => {
            if(res.data.status === true)
            {
                console.log(res);
                props.fetchTranscribe();
                clearInterval(interval);
            } else if(res.data.status === false)
            {
                console.log(res);
            }
            else
            {
                console.log(res);
            }
        }).catch(error => console.log(error))
    }

    useEffect(()=>{

        let index = props.transcribe.transcribe.findIndex(({status}) => status === "0");
        console.log(index);

        let interval = setInterval(() => {
            if(index >= 0){
                checkTranscribeStatus(props.transcribe.transcribe[index].job_name,interval);
            }
            else{
                clearInterval(interval);
            }
        }, 5000);


    },[transcribeData])

    useEffect(() => {
        // Fetch Transcribe languages
        try{
            axios.get(`${baseURL}fetch-transcribe-languages`,config).then(res => {
                if(res.data.status === true)
                {
                    setLanguages(res.data.data.languages);
                } else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger')
                }
                else
                {
                    props.setAlert("Something went wrong","danger")
                }
            }).catch(error => console.log(error))
        }catch (error){
            console.log(error);
        }
    },[])
    return (
        <>
            <Header />
            <Helmet>
                <title>Sonority | Upload-N-Transcribe </title>
            </Helmet>
            <section className="add-account-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add-heading text-white">
                                <h4>Upload Audio Or Select A Track</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="upload-file text-center">
                                <div className="row mt-5">
                                    <div className="col-md-11 mx-auto">
                                        <Alert />
                                        <div className="upload-box mt-5">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(e) => handleUpload(e)}/>
                                                <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                            </div>
                                            <span><img src={upload} alt /></span>
                                            <p className="mt-3">Click to upload file</p>
                                            <h6>or</h6>
                                            {progress !== undefined ? <p className="mt-3">Uploading... {progress} %</p>: null}
                                            <div className="row d-flex justify-content-center">
                                                <div className="download-btn mt-4 col-md-4">
                                                    <select className="form-control wave-select" onChange={(e) => handleOnChange(e)} style={{backgroundColor:"#683097",color:"#fff"}}>
                                                        <option default>Select A Track</option>
                                                        <optgroup label="Audio Track">
                                                            {tracks.audio.map(item => <option value={item.output_audio}>{item.name}</option> )}
                                                        </optgroup>
                                                        <optgroup label="Music Track">
                                                            {tracks.music.map(item => <option value={item.output_audio}>{item.title}</option> )}
                                                        </optgroup>
                                                        <optgroup label="Voice Track">
                                                            {tracks.tts.map(item => <option value={item.output_audio}>{item.title}</option> )}
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                            <h6 className="mt-4 mb-5">up to 100MB is allowed.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="language">
                                <div className="form-group">
                                    <label className="text-white">Please Select the Audio Language </label>
                                    <select value={finalData.language_id} className="form-control wave-select" onChange={(e) => handleOnTranscribeLanguageChange(e)} style={{backgroundColor:"#683097",color:"#fff"}}>
                                        {languages.map(item => <option value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <button className="btn-change8 float-right mt-4" onClick={clickTranscribeButton}>{props.loader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Transcribe</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <br /><br /><br /><br /> */}
                <div className="container Transcribed-sec ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add-heading text-white">
                                <h4>Your Transcribed Tracks</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row   mt-4">
                        <div className="col-md-12">
                             <div className="row transcribe">
                                 {
                                     transcribeData.map((item,index)=>{
                                         return(
                                             <TranscribeTrack item={item} key={index} />
                                         )
                                     })
                                 }
                             </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
    tracks: state.tracks,
    transcribe: state.transcribe
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        setAlert: (message,type) => dispatch(setAlert(message,type)),
        removeLoader: () => dispatch(removeLoader()),
        fetchTracks: () => dispatch(fetchTracks()),
        fetchTranscribe : () => dispatch(fetchTranscribe())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    UploadTranscribe
);
