import {
    FETCH_TRACKS, SET_ALERT
} from './Types';
import axios from "axios";
import {baseURL} from "../globals/global";
import {setAlert} from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Fetch Track
export const fetchTracks = () => dispatch => {
    setAuthToken(localStorage.token);
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        axios.get(`${baseURL}fetch-all`, config).then((res)=>{
            if(res.data.status === true)
            {
                dispatch({type:FETCH_TRACKS,payload:res.data.data});
            }
            else
            {
                setAlert('Something went wrong. Please try again latter','danger')
            }
        }).catch((error)=>{
           setAlert('Something went wrong. Please try again latter','danger')
        });
    } catch (err) {
        console.log(err);
    }
}