export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const OPEN_CLOSE_MODAL = "OPEN_CLOSE_MODAL";
export const SET_LOADER = "SET_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_VERIFY_LOADER = "CLEAR_VERIFY_LOADER";
export const FETCH_CLIENT_TEAM_ACCOUNT = "FETCH_CLIENT_TEAM_ACCOUNT";
export const CLEAR_CLIENT_TEAM_ACCOUNT = "CLEAR_CLIENT_TEAM_ACCOUNT";
export const FETCH_TRACKS = "FETCH_TRACKS";
export const CLEAR_TRACKS = "CLEAR_TRACKS";
export const REMOVE_TRACKS = "REMOVE_TRACKS";
export const SET_TRAINING = "SET_TRAINING";
export const FETCH_TRANSCRIBE = "FETCH_TRANSCRIBE";
export const GET_BRANDING_DATA = "GET_BRANDING_DATA";
export const CLEAR_BRANDING_DATA = "CLEAR_BRANDING_DATA";
