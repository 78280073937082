import React, { useEffect, useState } from "react";
import pause from "../../assets/images/pause.png";
import sound from "../../assets/images/sound.png";
import play from "../../assets/images/play.png";
import playWave from "../../assets/images/trackPlay.gif";
import wave from "../../assets/images/wave.png";
import close from "../../assets/images/close.png";

let audio = "";
const ATrack = (props) => {
  const [localValue, setLocalValue] = useState({
    type: "",
    name: "",
    created: "",
    audioFile: "",
    background: "",
    fileType: "",
    mergeType: "",
  });
  const [playing, setPlaying] = useState("no");

  useEffect(() => {
    if (playing === "no") {
      if (audio !== "") {
        audio.pause();
      }
    }
    if (playing === "yes") {
      if (audio !== "") {
        audio.play();
      }
    }
    if (playing === "loading") {
      // setTimeout(() =>{
      if (audio !== "") {
        audio.pause();
      }
      audio = new Audio(localValue.audioFile);
      const audioPromise = audio.play();
      if (audioPromise !== undefined) {
        audioPromise
          .then(() => {
            setPlaying("yes");
          })
          .catch((error) => console.log(error, "Error playing Audio"));
      }
      // },5000)
    }
    if (audio !== "") {
      audio.addEventListener("ended", () => {
        setPlaying("no");
      });
    }
  }, [playing]);

  useEffect(() => {
    setLocalValue({
      type: props.type,
      name:
        props.type === "Voice" && props.fileType === "big"
          ? props.item.name
          : props.type === "Voice"
          ? props.item.title
          : props.type === "Music" && props.fileType === "big"
          ? props.item.name
          : props.type === "Music"
          ? props.item.title
          : props.type === "Audio"
          ? props.item.name
          : props.type === "Sound"
          ? props.item.name
          : null,
      created: props.item.created,
      audioFile:
        props.fileType === "big"
          ? props.item.music || props.item.audio
          : props.type === "Sound"
          ? props.item.url
          : props.item.output_audio,
      background:
        props.type === "Voice"
          ? "bg3"
          : props.type === "Music"
          ? "bg2"
          : props.type === "Audio"
          ? "bg1"
          : props.type === "Sound"
          ? "bg4"
          : null,
      fileType: props.fileType,
      mergeType: props.mergeType ? props.mergeType : null,
    });

    return () => {
      setLocalValue({
        type: "",
        name: "",
        created: "",
        audioFile: "",
        background: "",
        fileType: "",
        mergeType: "",
      });
    };
  }, []);

  const handlePlay = () => {
    setPlaying("loading");
  };

  const handlePause = () => {
    setPlaying("no");
  };

  const handleVolumeChange = (e) => {
    if (audio !== "") {
      audio.volume = e.target.value / 100;
    }
    props.volumeControl(e.target.value / 100, props.item.id, props.mergeType);
  };

  return (
    <div
      className={
        "music-line text-white  mt-4 d-flex align-items-center justify-content-between " +
        localValue.background
      }
    >
      <div className="play-text">
        {playing === "loading" ? (
          <i className="fa fa-spinner fa-spin ml-3 f25" />
        ) : playing === "yes" ? (
          <span onClick={() => handlePause()}>
            <img src={pause} alt="pause" className="mr-3" /> {localValue.name}
          </span>
        ) : (
          <span onClick={() => handlePlay()}>
            <img src={play} alt="play" className="mr-3" />{" "}
            {localValue.name.slice(0, 20) + "..."}
          </span>
        )}

        {localValue.fileType === "big" ? (
          <span className="ml-3">
            <img src={playing === "yes" ? playWave : wave} alt="wave" />
          </span>
        ) : null}
      </div>
      {localValue.fileType === "big" ? (
        <div className="volume-cancel d-flex align-items-center">
          <span className="mr-3">
            <input
              type="range"
              className="form-range"
              id="customRange1"
              min="0"
              max="100"
              onChange={(e) => handleVolumeChange(e)}
            />
          </span>
          <span
            onClick={(e) =>
              props.removeClick(e, props.item.id, props.mergeType)
            }
          >
            <img src={close} alt="close" />
          </span>
        </div>
      ) : (
        <div className="volume-cancel">
          <span>
            <img src={sound} alt="wave" className="mr-2" />
          </span>
        </div>
      )}
    </div>
    // <div className="music-line mt-3 mb-3 d-flex justify-content-between align-items-center">
    //     <div className="play-wave">
    //         <span><img src={play} alt className="mr-3" />Voice-Track</span>
    //         <span className="ml-3"><img src={wave} alt /></span>
    //     </div>
    //     <div className="volume-cancel d-flex align-items-center">
    //         <span className="mr-3"><input type="range" className="form-range" id="customRange1" /></span>
    //         <span><img src={close} alt /></span>
    //     </div>
    // </div>
  );
};

export default ATrack;
