import { FETCH_TRACKS } from "../actions/Types";

const initialState = {
    merge: [],
    music:[],
    tts:[],
    podcast:[]
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TRACKS:
            return { ...state,merge: payload.merge,music:payload.music,tts:payload.tts,podcast:payload.podcast };
        default:
            return state;
    }
}
