import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.png";
import Navbar from "./Navbar";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

const Header = ({ brandingData, auth }) => {
  const [logo, setLogo] = useState("");
  const dispatch = useDispatch();

//  console.log(brandingData);
  useEffect(() => {
    if (brandingData.brandingdata) {
      setLogo(brandingData.brandingdata.logo);
    } else {
      setLogo("");
    }
  }, [brandingData.brandingdata]);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand" to="dashboard">
              <img
                src={logo !== "" ? logo : Logo}
                alt="logo"
                className="img-fluid sonority-favicon-icon"
                style={logo === "" ? { width: "152px" } : { width: "100px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span>
                <i className="fas fa-bars" />
              </span>
            </button>
            <Navbar />
          </div>
        </nav>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  brandingData: state.brandingdata,
  auth: state.auth,
});

export default connect(mapStateToProps)(Header);
