import React, { useEffect, useState } from "react";
import upload from "../../assets/images/upload.png";
import { removeLoader, setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL, config } from "../../globals/global";
import Alert from "../Alert/Alert";

const UploadFile = (props) => {
  const [tracks, setTracks] = useState({
    tts: [],
    audio: [],
    music: [],
    wave: [],
  });
  const [progress, setProgress] = useState();
  const [loading, setLoading] = useState(false);
  const handleUpload = (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("audio", selectedFile);

    axios
      .post(`${baseURL}upload-audio`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          props.uploadComplete({
            campaignId: res.data.data.campaignId,
            campaignName: res.data.data.campaignName,
            url: res.data.data.url,
            startTime: 0,
            endTime: 0,
            duration: 0,
          });
        } else if (res.data.status === false) {
          props.setAlert(res.data.message, "danger");
        } else {
          props.setAlert("Something went wrong", "danger");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOnChange = (e) => {
    const audioUrl = e.target.value;
    setLoading(true);
    try {
      axios
        .post(`${baseURL}upload-audio`, { url: audioUrl }, config)
        .then((res) => {
          if (res.data.status === true) {
            setLoading(false);
            props.uploadComplete({
              campaignId: res.data.data.campaignId,
              campaignName: res.data.data.campaignName,
              url: res.data.data.url,
              startTime: 0,
              endTime: 0,
              duration: 0,
            });
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong", "danger");
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setTracks({
      audio: props.tracks.merge,
      tts: props.tracks.tts,
      music: props.tracks.music,
      wave: props.tracks.wave,
    });
  }, [props.tracks]);

  useEffect(() => {
    props.fetchTracks();
  }, []);
  return (
    <div className="upload-file text-center">
      <div className="row py-4">
        <div className="col-md-11 mx-auto">
          <Alert />
          <div className="upload-box mt-5">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
                onChange={(e) => handleUpload(e)}
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                Choose file
              </label>
            </div>
            <span>
              <img src={upload} alt />
            </span>
            <p className="mt-3">Click to upload file</p>
            <h6>or</h6>
            {progress !== undefined ? (
              <p className="mt-3">Uploading... {progress} %</p>
            ) : null}
            <div className="row d-flex justify-content-center">
              <div className="download-btn mt-4 col-md-4">
                {loading === true ? (
                  <div class="text-center">
                    <div
                      class="spinner-border"
                      role="status"
                      style={{
                        color: "#fff",
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : null}
                )
                <select
                  className="form-control wave-select"
                  onChange={(e) => handleOnChange(e)}
                  style={{ backgroundColor: "#683097", color: "#fff" }}
                >
                  <option default>Select A Track</option>
                  <optgroup label="Audio Track">
                    {tracks.audio.map((item) => (
                      <option value={item.output_audio}>{item.name}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Music Track">
                    {tracks.music.map((item) => (
                      <option value={item.output_audio}>{item.title}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Voice Track">
                    {tracks.tts.map((item) => (
                      <option value={item.output_audio}>{item.title}</option>
                    ))}
                  </optgroup>
                </select>
              </div>
            </div>
            <h6 className="mt-4 mb-5">up to 100MB is allowed.</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
