import React, { useState } from "react";
import upload from "../../assets/images/upload.png";
import wave3 from "../../assets/images/wave3.png";
import playBtn from "../../assets/images/playbtns.png";
import preview2 from "../../assets/images/preview2.png";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import UploadFile from "./UploadFile";
import AudioFile from "./AudioFile";
import Customize from "./Customize";
import { removeLoader, setLoader } from "../../actions/loader";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";

function WaveVideo() {
  const [show, setShow] = useState({
    show: "upload",
  });

  const [campaign, setCampaign] = useState({
    campaignId: "",
    campaignName: "",
    url: "",
    startTime: 0,
    endTime: 0,
    duration: 0,
  });

  const onUploadComplete = (response) => {
    setCampaign(response);
    setShow({
      show: "audio",
    });
  };

  const onTrimComplete = (response) => {
    setCampaign(response);
    setShow({
      show: "customize",
    });
  };

  const backToUpload = (e) => {
    if (e === "upload") {
      setShow({ show: "upload" });
    }
  };

  return (
    <>
      <Header />
      <section className="wavevidoe-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <div
                className="nav flex-column"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  className={
                    "nav-link text-center " +
                    (show.show === "upload" ? "active" : "")
                  }
                  id="v-pills-home-tab"
                  href="#"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  onClick={() => backToUpload("upload")}
                >
                  <span className="d-block">1</span>
                  <h6>Upload</h6>
                </a>
                <a
                  className={
                    "nav-link text-center " +
                    (show.show === "audio" ? "active" : "")
                  }
                  id="v-pills-profile-tab"
                  href="#"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                  onClick={() => backToUpload("audio")}
                >
                  <span className="d-block">2</span>
                  <h6>Audio</h6>
                </a>
                <a
                  className={
                    "nav-link text-center " +
                    (show.show === "customize" ? "active" : "")
                  }
                  id="v-pills-messages-tab"
                  href="#"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <span className="d-block">3</span>
                  <h6>Customize</h6>
                </a>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 bgcolor">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className={
                    "tab-pane fade " +
                    (show.show === "upload" ? "show active" : "")
                  }
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <UploadFile uploadComplete={onUploadComplete} />
                </div>
                <div
                  className={
                    "tab-pane fade " +
                    (show.show === "audio" ? "show active" : "")
                  }
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <AudioFile campaign={campaign} click={onTrimComplete} />
                </div>
                <div
                  className={
                    "tab-pane fade " +
                    (show.show === "customize" ? "show active" : "")
                  }
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <Customize campaign={campaign} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    removeLoader: () => dispatch(removeLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WaveVideo);
