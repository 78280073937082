import React from 'react'
import {Link} from 'react-router-dom'


function DashCount(props) {
    return (
            <Link to={props.href}>
                 <div className="voice-track mt-2">
                    <div className="row">
                      <div className="col-md-8 col-8 d-flex align-items-center">
                        <div className="icon-track">
                          <img src={props.img} alt="icon" className="img-fluid" />
                          <span>{props.name}</span>
                        </div>
                      </div>
                      <div className="col-md-4 col-4 d-flex justify-content-end align-items-center">
                        <div className="arrow-icon">
                          <span><img src={props.arrowiconimg} /></span>
                        </div>
                      </div>
                    </div>
                </div>
          </Link>
    )
}

export default DashCount
