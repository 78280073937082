import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function PrivateRoute({
                          component: Component,
                          auth: { isAuthenticated, loading },
                          ...rest
                      }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated && !loading ? (
                    <Redirect to="/" />
                ) : isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <></>
                )
            }
        />
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
