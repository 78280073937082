import React from 'react'
import footerImage from '../../assets/images/footerimg.png';
function Footer() {
    return (
        <>
          <section className="footer">
  <div className="container">
    <div className="row">
      <div className="col-md-4 d-flex align-items-center">
        <div className="footer-menu">
          <ul className="list-inline m-0 d-flex">
            <li><a href="https://reelapps.io/terms.html" target="_blank">TERMS</a></li>
            <li><a href="https://reelapps.io/privacy.html" target="_blank">PRIVACY</a></li>
            <li><a href="https://vineasx.helpscoutdocs.com/" target="_blank">SUPPORT</a></li>
          </ul>
        </div> 
      </div>
      <div className="col-md-5 d-flex align-items-center text-white justify-content-center">
        <p className="m-0 text-center"> © {(new Date().getFullYear())}, All Rights Reserved to VineaSX Solutions LLC. Developed and Maintained by
                                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6</a> </p>
      </div>
      <div className="col-md-3 d-flex align-items-center justify-content-center">
        <div className="footer-img">
        <a href="http://vineasx.com/" target="_blank"><img src={footerImage} alt className="img-fluid" /></a>
        </div>
      </div>
    </div>
  </div>
</section>
   
        </>
    )
}

export default Footer
