import React, {useEffect, useState} from 'react';
import ClientAccount from "./ClientAccount";
import TeamMember from "./TeamMember";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import {baseURL, config} from "../../globals/global";
import {removeLoader, setLoader} from "../../actions/loader";
import {setAlert} from "../../actions/alert";
import {fetchTracks} from "../../actions/tracks";
import {connect} from "react-redux";
import Alert from "../Alert/Alert";
import {Helmet} from "react-helmet";
import auth from "../../reducers/auth";

const Accounts = (props) => {
    const [accounts, setAccounts]  = useState({
        client:[],
        virtual:[]
    })

    const [formValue, setFormValue] = useState({
        type:"client",
        name:"",
        email:"",
        password:""
    })
    const handleChange = (e) => {
        setFormValue({
            ...formValue,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.setLoader();

        let config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": props.auth.token
            },
        }

        try{
            axios.post(`${baseURL}create-client`, formValue,config).then((res)=>{
                if(res.data.status === true)
                {
                    fetchClients();
                    props.setAlert(res.data.message,'success');
                    props.removeLoader();
                }
                else if(res.data.status === false)
                {
                    props.setAlert(res.data.message,'danger');
                    props.removeLoader();
                }
                else
                {
                    props.setAlert('Something went wrong','danger');
                    props.removeLoader();
                }
            }).catch((error) => {
                console.log(error,'Something went wrong')
                props.removeLoader();
            })
        }catch (error){
            console.log(error,'Something went wrong')
            props.removeLoader();
        }
    }

    const fetchClients = () => {
        try{
            axios.get(`${baseURL}fetch-clients`).then((res)=>{
                setAccounts({
                    ...accounts,
                    client: res.data.data.clients,
                    virtual: res.data.data.team_members
                })
            })
        }catch (error){
            console.log(error)
        }
        return () => {

        };
    }
    useEffect(() => {
        fetchClients();
    }, []);
    return (
        <>
        <Header />
            <Helmet>
                <title>Sonority | Sub Accounts </title>
            </Helmet>
        <section className="add-account-sec" style={{height: "auto"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="add-heading text-white">
                            <h4>Add Account</h4>
                        </div>
                    </div>
                </div>
                <Alert />
                <div className="row">
                    <div className="col-md-12">
                        <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                            <div className="add-box text-white">
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label htmlFor="inputEmail4">Account Type</label>
                                            <select className="form-control" name="type" value={formValue.type} onChange={(e) => handleChange(e)}>
                                                <option value="client">Client Account</option>
                                                <option value="team">Team Member</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label htmlFor="inputPassword4">Client Name</label>
                                            <input type="text" className="form-control" name="name" id="inputPassword4" placeholder="Enter Client Name" onChange={(e) => handleChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label htmlFor="inputEmail4">Password</label>
                                            <input type="password" className="form-control" name="password" id="inputPassword4" placeholder="........" onChange={(e) => handleChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label htmlFor="inputPassword4">Client Email Address</label>
                                            <input type="text" className="form-control" name="email" id="inputPassword4" placeholder="your email" onChange={(e) => handleChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <div className="save-btn">
                                            <button className="btn-change8" type="submit">{props.loader ? <i className="fa fa-spinner fa-spin mr-2"/> : null} Create Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ClientAccount accounts={accounts.client} fetchClients={fetchClients} />
                <TeamMember accounts={accounts.virtual} fetchClients={fetchClients} />
            </div>
        </section>
        <Footer />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        setAlert: (message,type) => dispatch(setAlert(message,type)),
        removeLoader: () => dispatch(removeLoader())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    Accounts
);