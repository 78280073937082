import axios from "axios";
import { baseURL } from "../globals/global";

import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  USER_LOADED,
  LOGOUT,
  OPEN_CLOSE_MODAL,
} from "./Types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert, removeAlert } from "./alert";
import { removeLoader, setLoader, setVerifyLoader } from "./loader";
import { getBrandingDataFromServer } from "./branding";

// Load User
export const loadUser = () => async (dispatch) => {
  dispatch(setVerifyLoader());
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.post(`${baseURL}profile`);
    if (res.data.status !== false) {
      dispatch({ type: USER_LOADED, payload: res.data });
      dispatch(getBrandingDataFromServer({ user_id: res.data.id }));
      dispatch(removeLoader());
    } else {
      dispatch({ type: AUTH_ERROR });
      dispatch(removeLoader());
    }
  } catch (err) {
    dispatch(removeLoader());
    dispatch({ type: AUTH_ERROR });
  }
};

// Login
export const login = (email, password, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post(`${baseURL}login`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data.token,
      });
      dispatch({ type: USER_LOADED, payload: res.data.data.user });
      dispatch(removeAlert());
      dispatch(removeLoader());
      history.push("/dashboard");
    } else {
      dispatch({ type: LOGIN_FAIL });
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    dispatch(removeLoader());
    dispatch(setAlert("Server Error, Please try again", "danger"));
  }
};

// Master-Login
export const masterLogin =
  (useremail, email, password, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ useremail, email, password });

    const res = await axios.post(`${baseURL}master-login`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data.token,
      });
      dispatch({ type: USER_LOADED, payload: res.data.data.user });
      dispatch(removeLoader());
      history.push("/dashboard");
    } else {
      dispatch({ type: LOGIN_FAIL });
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  };

// Forgot password
export const forgot_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(`${baseURL}forgot-password`, body, config);
    if (res.data.status === true) {
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(removeLoader());
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err);
    dispatch(removeLoader());
  }
};

// Reset password
export const reset_password =
  (userPassword, token, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { password, confirm_password } = userPassword;

    const body = JSON.stringify({
      password,
      password_token: token,
      confirm_password,
    });

    try {
      const res = await axios.post(`${baseURL}reset-password`, body, config);
      if (res.data.status === true) {
        dispatch(setAlert(res.data.message, "success"));
        dispatch(removeLoader());
        history.push("/");
      } else {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch(removeLoader());
      }
    } catch (err) {
      console.log(err);
    }
  };

// Update Personal Information
export const updateInformation = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`${baseURL}profile`, data, config);
    if (res.data.status === true) {
      if (
        data.hasOwnProperty("password") &&
        data.hasOwnProperty("new_password")
      ) {
        // request was to change the password.
        dispatch(
          setAlert(
            "Password changed successfully. Signing you out. Please Sign in again",
            "success"
          )
        );
        dispatch(logout());
        dispatch(removeAlert());
      } else {
        dispatch(removeAlert());
        await dispatch(loadUser());
        dispatch(setAlert(res.data.message, "success"));
        dispatch(removeLoader());
      }
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
  }
};

// Update Email
export const updateEmail = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`${baseURL}change-email`, data, config);
    if (res.data.status === true) {
      dispatch(removeAlert());
      await dispatch(loadUser());
      dispatch(setAlert(res.data.message, "success"));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
  }
};

// GDPR Request
export const gdprRequest = (type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(`${baseURL}${type}-account`, config);
    if (res.data.status === true) {
      dispatch({
        type: LOGOUT,
      });
      dispatch(setAlert("Your account has been deleted", "danger"));
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
  }
};

// AvatarUpdate
export const updateAvatar =
  (data, close, setUploadStuff, setSourceCancel) => async (dispatch) => {
    let source = axios.CancelToken.source();
    setSourceCancel(source);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        if (percentage < 99) {
          setUploadStuff((value) => ({
            ...value,
            uploadedPercentage: percentage,
          }));
        }
      },
      cancelToken: source.token,
    };

    try {
      const res = await axios.post(`${baseURL}file-upload`, data, config);
      if (res.data.status === true) {
        await dispatch(loadUser());
        dispatch(setAlert(res.data.message, "success"));
        dispatch(removeLoader());
        close();
      } else {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch(removeLoader());
      }
    } catch (err) {
      console.log(err);
    }
  };

// close dashboard modal
export const openCloseModal = (data) => async (dispatch) => {
  dispatch({ type: OPEN_CLOSE_MODAL, payload: data });
};

// Logout
export const logout = (id) => async (dispatch) => {
  dispatch(setLoader(false));
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id });

  try {
    const res = await axios.post(`${baseURL}logout`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: LOGOUT,
      });
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(removeLoader());
  }
};
