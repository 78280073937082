import {
    FETCH_TRANSCRIBE
} from './Types';
import axios from "axios";
import {baseURL, config} from "../globals/global";

export const fetchTranscribe = () => dispatch => {
    try {
        axios.get(`${baseURL}fetch-transcribes`, config).then(res => {
            if (res.data.status === true) {
                dispatch({type:FETCH_TRANSCRIBE,payload:res.data.data});
            }else{
            }
        }).catch(error => console.log(error));

    } catch (error) {
        console.log(error)
    }
}