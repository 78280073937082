import React from 'react'

function FeatureImageLeft(props) {
    return (
        <>
               <section className="sec-3">
  <div className="container">
    <div className="row">
      <div className="col-md-6 d-flex align-items-center">
        <div className="sec-3-content px-lg-5 px-md-5 text-white">
          <span className="color text-uppercase text-uppercase">{props.title}</span>
          <h2>{props.heading}
            <span className="d-lg-block"> {props.subheading}</span> </h2>
          <p>
             {props.para}
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="sec-3-imgages"> 
          <img src={props.bg2img} />
        </div>
      </div>
    </div>
  </div>
</section> 
        </>
    )
}

export default FeatureImageLeft
