import React, { useState, useEffect } from "react";
import video from "../../assets/images/video.png";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import trainingImage from "../../assets/images/training.png";
import SearchingResult from "./SearchingResult";

function ImportantTopic(props) {
  const [q, setQ] = useState("");

  const searchImportant = (e) => {
    setQ(e.target.value);
  };

  const search = (datas) => {
    return datas.filter(
      (data) => data.name.toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  const [searchingData, setSearchingData] = useState([]);
  const [showSearchingComponent, setShowSearchingComponent] = useState(false);
  const articles = useSelector((state) => state.training.articles);

  // useEffect(() => {
  //   if (articlesData.length > 0) {
  //     setArticles(articles);
  //   }
  // }, [articles]);

  const searchVideosAndArticles = () => {
    let data = [];

    articles.map((value) =>
      JSON.parse(value.data).map((item) => {
        if (item.title.includes(q)) {
          //toLowerCase dosen't work q.toLowerCase()
          data.push({
            article_id: value.id,
            item_id: item.id,
            image: item.feature_url,
            title: item.title,
            created: item.created,
          });
          console.log("fghjk");
        }
      })
    );
    setShowSearchingComponent(true);
    setSearchingData([...data]);
    window.scrollBy(0, 500);
  };

  useEffect(() => {
    if (q === "") {
      setShowSearchingComponent(false);
    }
  }, [q]);

  return (
    <>
      <section className="traning-sec">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="help-box">
                <div className="form-group text-white">
                  <label htmlFor="exampleInputEmail1">
                    What do you want to know?
                  </label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      onChange={(e) => searchImportant(e)}
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="Search"
                    />
                    <div
                      className="input-group-prepend"
                      onClick={searchVideosAndArticles}
                    >
                      <div className="input-group-text">
                        <i className="fas fa-search" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="traning-img">
                <img src={trainingImage} alt className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="important-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1>Important Topics</h1>
            </div>
          </div>
          <div className="row mt-5 d-flex justify-content-center">
            {showSearchingComponent ? (
              <SearchingResult
                searchingData={searchingData}
                setSearchingData={setSearchingData}
                setShowSearchingComponent={setShowSearchingComponent}
              />
            ) : (
              props.categories.map((item) => (
                <div className="col-md-3">
                  <NavLink to={"article?cid=" + item.id}>
                    <div className="important-box text-center">
                      <span>
                        <img src={item.logo} alt className="size" />
                      </span>
                      <h4 className="mt-4">{item.name}</h4>
                    </div>
                  </NavLink>
                </div>
              ))
            )}

            {/* {props.categories.length > 0
              ? search(props.categories).map((item) => (
                  <div className="col-md-3">
                    <NavLink to={"article?cid=" + item.id}>
                      <div className="important-box text-center">
                        <span>
                          <img src={item.logo} alt className="size" />
                        </span>
                        <h4 className="mt-4">{item.name}</h4>
                      </div>
                    </NavLink>
                  </div>
                ))
              : null} */}
          </div>

          {/*<div className="row mt-5">*/}
          {/*  <div className="col-md-12 d-flex justify-content-center">*/}
          {/*    <div className="view-all">*/}
          {/*      <a href="#" className="btn-change7">View All Topics</a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>
    </>
  );
}

export default ImportantTopic;
