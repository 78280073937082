import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import wave1 from "./../../assets/images/wave1.jpg";
import wave2 from "./../../assets/images/wave2.jpg";
import wave3 from "./../../assets/images/wave3.jpg";
import wave4 from "./../../assets/images/wave4.jpg";
import WaveForm from "./Preview/WaveForm";
import TextObject from "./Preview/TextObject";
import axios from "axios";
import { baseURL, config } from "../../globals/global";
import Alert from "../Alert/Alert";
import { removeLoader, setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
import { fetchTracks } from "../../actions/tracks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const Customize = (props) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [waveBg, setWaveBg] = useState("#000000");
  const [campName, setCampName] = useState("");
  const [general, setGeneral] = useState({
    dimension: "16:9",
    width: 1920,
    height: 1080,
    backgroundColor: "#000000",
  });
  const [waveForm, setWaveForm] = useState({
    posx: 0,
    posy: 0,
    width: 100,
    height: 70,
    color: "#ffffff",
    mode: "p2p",
  });
  const [image, setImage] = useState({
    posx: 0,
    posy: 0,
    height: 1080,
    width: 1920,
    url: "",
  });
  const [text, setText] = useState({
    fontUrl: "",
    pox: 0,
    posy: 0,
    fontFamily: "",
    fontSize: 24,
    text: "",
    color: "#ffffff",
  });

  const [finalData, setFinalData] = useState({
    campaignid: props.campaign.campaignId,
    created_json: {
      podcast_name: props.campaign.campaignName,
      newPodcastName: campName,
      audio_file_url: props.campaign.url,
      start_time: props.campaign.startTime,
      end_time: props.campaign.endTime,
      duration: props.campaign.duration,
      audio_add_caption: [],
      audio_translate_caption: [],
      customize: {
        general: [general],
        waveform: [waveForm],
        progress: [
          {
            color: "",
          },
        ],
        image: [image],
        text: [text],
      },
    },
  });

  useEffect(() => {
    setFinalData({
      campaignid: props.campaign.campaignId,
      created_json: {
        podcast_name: props.campaign.campaignName,
        newPodcastName: campName,
        audio_file_url: props.campaign.url,
        start_time: props.campaign.startTime,
        end_time: props.campaign.endTime,
        duration: props.campaign.duration,
        audio_add_caption: [],
        audio_translate_caption: [],
        customize: {
          general: [general],
          waveform: [waveForm],
          progress: [
            {
              color: "",
            },
          ],
          image: [image],
          text: [text],
        },
      },
    });
  }, [props.campaign, waveForm, image, text, general, campName]);

  const handleColorChangeComplete = (color) => {
    setGeneral({
      ...general,
      backgroundColor: color.hex,
    });

    setWaveBg(color.hex);
  };

  const handleTextColorChangeComplete = (color) => {
    setText({
      ...text,
      color: color.hex,
    });
  };

  const handleWaveClick = (type) => {
    setWaveForm({
      ...waveForm,
      mode: type,
    });
  };

  const handleChangeText = (e) => {
    setText({
      ...text,
      text: e.target.value,
    });
  };

  const handleUpload = (e) => {
    const bytes = e.target.files[0].size;
    const extension = e.target.files[0].name.split(".").pop();

    if (bytes > 5242880) {
      alert("Please select an image file not more than 5Mb.");
    } else {
      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", props.auth.user.id);
        formData.append("image", e.target.files[0]);

        axios.post(`${baseURL}/upload-image`, formData).then((res) => {
          if (res.data.status === true) {
            setImage({ ...image, url: res.data.data.url });
            console.log(res);
            setWaveBg(`url(${res.data.data.url})`);
            setLoading(false);
          } else if (res.status !== 200) {
            alert("Image not found");
            setLoading(false);
          }
        });
      } else {
        alert("Please select .png,.jpg or .jpeg audio file.");
      }
    }
  };

  const handleRender = () => {
    setLoading1(true);
    try {
      axios
        .post(`${baseURL}create-wave`, finalData, config)
        .then((res) => {
          if (res.data.status === true) {
            props.setAlert(res.data.message, "success");
            props.history.push("dashboard");
            setLoading1(false);
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong.", "danger");
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  };

  const projectName = (e) => {
    setCampName(e.target.value);
  };

  return (
    <div className="customize-sec mt-4">
      <Alert />
      <div className="row">
        <div className="col-md-2 d-flex justify-content-center">
          <div className="customize-menu text-center">
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link"
                id="v-pills-general-tab"
                data-toggle="pill"
                href="#v-pills-general"
                role="tab"
                aria-controls="v-pills-general"
                aria-selected="false"
              >
                Color
              </a>
              <a
                className="nav-link"
                id="v-pills-wavefrom-tab"
                data-toggle="pill"
                href="#v-pills-wavefrom"
                role="tab"
                aria-controls="v-pills-wavefrom"
                aria-selected="false"
              >
                WAVEFORM
              </a>
              <a
                className="nav-link"
                id="v-pills-image-tab"
                data-toggle="pill"
                href="#v-pills-image"
                role="tab"
                aria-controls="v-pills-image"
                aria-selected="false"
              >
                IMAGE
              </a>
              <a
                className="nav-link"
                id="v-pills-text-tab"
                data-toggle="pill"
                href="#v-pills-text"
                role="tab"
                aria-controls="v-pills-text"
                aria-selected="false"
              >
                TEXT
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{ backgroundColor: "#6e1ba9", borderRadius: 20 }}
        >
          <div className="customize-content">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-general"
                role="tabpanel"
                aria-labelledby="v-pills-general-tab"
              >
                <div className="general-content">
                  <div className="row">
                    <div className="col-md-12 p-3">
                      <h6 className="text-white m-0">
                        Select a Background Color
                      </h6>
                      <SketchPicker
                        color={general.backgroundColor}
                        onChangeComplete={handleColorChangeComplete}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-wavefrom"
                role="tabpanel"
                aria-labelledby="v-pills-wavefrom-tab"
              >
                <div className="wavefrom-content">
                  <div className="dimension mt-4">
                    <span className="d-blcok text-white">
                      Choose a Wavefrom for your Podcast
                    </span>
                    <div className="row mt-3">
                      <div className="col-md-6 ">
                        <img
                          src={wave1}
                          alt="wave1"
                          className={
                            waveForm.mode === "line" ? "selected" : " "
                          }
                          onClick={() => handleWaveClick("line")}
                        />
                      </div>
                      <div className="col-md-6">
                        <img
                          src={wave2}
                          alt="wave1"
                          className={
                            waveForm.mode === "cline" ? "selected" : " "
                          }
                          onClick={() => handleWaveClick("cline")}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <img
                          src={wave3}
                          alt="wave1"
                          className={waveForm.mode === "p2p" ? "selected" : " "}
                          onClick={() => handleWaveClick("p2p")}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <img
                          src={wave4}
                          alt="wave1"
                          className={
                            waveForm.mode === "point" ? "selected" : " "
                          }
                          onClick={() => handleWaveClick("point")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-image"
                role="tabpanel"
                aria-labelledby="v-pills-image-tab"
              >
                <div className="images-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="tab-pane fade show active"
                        id="upload"
                        role="tabpanel"
                        aria-labelledby="upload-tab"
                      >
                        <label
                          className="d-flex border-line justify-content-center align-items-center flex-column mt-5 "
                          style={{
                            width: "100%",
                            padding: "20px 50px",
                            color: "#fff",
                          }}
                        >
                          <span>
                            <i className="fas fa-2x fa-cloud-upload-alt" />
                          </span>
                          <input
                            type="file"
                            name="upload-img"
                            id="upload-img"
                            onChange={(e) => handleUpload(e)}
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          {loading === true ? (
                            <div
                              class="text-center"
                              style={{ marginTop: "5px" }}
                            >
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : null}

                          <h6 className="text-white mt-3">
                            Upload an Image File
                          </h6>
                          <h6 className="text-white"> up to 5MB is allowed</h6>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-text"
                role="tabpanel"
                aria-labelledby="v-pills-text-tab"
              >
                <div className="text-content">
                  <div className="row p-2">
                    <div className="col-md-12 wave">
                      <h6 className="m-0 text-white">Write Some Text</h6>
                      <textarea
                        className="form-control"
                        value={text.text}
                        onChange={(e) => handleChangeText(e)}
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-12">
                      <h6 className="m-0 text-white">Color</h6>
                      <SketchPicker
                        color={text.color}
                        onChangeComplete={handleTextColorChangeComplete}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="preview-box">
            <h5 className="text-white text-center">PREVIEW</h5>
            <div
              className="canvas bg-custom"
              style={{ background: waveBg, margin: "auto" }}
            >
              <WaveForm wave={waveForm} />
              <TextObject text={text} />
            </div>
            <div
              className="render-video d-flex justify-content-around mt-3"
              style={{ alignItems: "center" }}
            >
              <span>
                <input
                  id="placeColor"
                  type="text"
                  onChange={(e) => projectName(e)}
                  placeholder="Project name"
                  style={{
                    backgroundColor: "#793fbb",
                    color: "#fff",
                    padding: " 10px 8px",
                    border: "none",
                    borderRadius: "10px",
                    outline: "none",
                  }}
                />
              </span>
              <a href="#" onClick={handleRender}>
                RENDER VIDEO
                {loading1 === true ? (
                  <div
                    class="text-center"
                    style={{ display: "inline-block", marginLeft: "5px" }}
                  >
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ width: "1rem", height: "1rem" }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Customize));
