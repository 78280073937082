import React from 'react'
import bg2 from '../../assets/images/bg2.png';
import bg3 from '../../assets/images/bg3.png';
import bg4 from '../../assets/images/bg4.png';
import bg5 from '../../assets/images/bg5.png';
import FeatureImageLeft from './FeatureImageLeft';
import FeatureImageRight from './FeatureImageRight';

function FeaturesContent() {
    return (
        <>
         <FeatureImageLeft
                  title="SAY GOODBYE TO FAKE-SOUNDING AI VOICEOVERS"
                  heading="Turn your text into a natural"
                  subheading="&amp; authentic voiceover"
                  para="Forget about spending time and money just to get a voiceover that captivates your viewers.
                  Simply choose from 15 diverse voices and let our cutting-edge AI convert your text into 
                  a human-sounding voiceover in under 60 seconds."
                  bg2img={bg2}
             />

             <FeatureImageRight
                bg3img={bg3}
                title="Get 1000s of music to choose from"
                heading="Stand out with unique music"
                subheading="for your content"
                para="  Level up from generic music that everybody else is using to unique 
                AI-generated tracks that elevate your content from the rest. Set the perfect
                mood with unlimited access to a rich library of music that has just what you need."
             />


            <FeatureImageLeft
                  title="SIT BACK AND LET OUR AI DO THE WORK FOR YOU"
                  heading="Produce a high-quality"
                  subheading="Audio-Track in 1 click"
                  para="Why bother tinkering with complicated audio-editing software when you
                   can just let our AI automate this for you? All you need is a single click to
                    merge all your voiceovers and music to get that polished, finalized content
                     ready for broadcast."
                  bg2img={bg5}
             />


            <FeatureImageRight
                bg3img={bg4}
                title="EFFORTLESSLY REPURPOSE YOUR AUDIO"
                heading="Convert your audio into a"
                subheading="WaveVideo seamlessly"
                para="Double your views and engagement with half the effort. Transform your audio
                     into a fully-customizable WaveVideo that is fully-optimized for your social
                      media, business presentation, website use, and more."
             />
 
        </>
    )
}

export default FeaturesContent
