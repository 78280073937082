import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

const AvatarUpdate = (props) => {

    const [memberships, setMemberships] = useState('Membership');

    useEffect(()=>{
        let length = props.user.membership.split('__').length;

        setMemberships(props.user.membership.split('__')[(length-1)]);

    },[memberships])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="user-details text-white text-center">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="inputGroupFile01"/>
                    </div>
                    <span><i className="far fa-user"/></span>
                    <h5 className="mt-3">{props.user.name}</h5>
                    <div className="uplaod-pic mt-4">
                        <a href="#" className="btn-change7">{memberships}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
});
export default connect(mapStateToProps)(AvatarUpdate);
