import React, { useEffect, useState } from "react";
import Icon1 from "../../assets/images/micon1.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MusicPlayer from "./MusicPlayer";
import { baseURL, config } from "../../globals/global";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert/Alert";
import { Helmet } from "react-helmet";

function MusicTrack(props) {
  const [data, setData] = useState({
    originalData: [],
    category: [],
    activity: [],
    moods: [],
  });
  const [show, setShow] = useState(false);
  const [finalData, setFinalData] = useState({
    title: "",
    duration: 15,
    playlist:
      data.moods.length > 0
        ? data.moods.find((item) => item.selected === true).playlist
        : "0.0.0",
  });

  const handleChange = (e) => {
    const duration = e.target.value;
    if (duration === "custom") {
      setShow(true);
    } else {
      setFinalData({
        ...finalData,
        duration: duration,
      });
      setShow(false);
    }
  };

  const handleTitleChange = (e) => {
    setFinalData({
      ...finalData,
      title: e.target.value,
    });
  };
  const handleCustomChangeDuration = (e) => {
    const customDuration = e.target.value;
    setFinalData({
      ...finalData,
      duration: customDuration,
    });
  };

  const handleClickCategory = (categoryId) => {
    const categoryData = data.category.map((item, index) => {
      return {
        ...item,
        selected: item.category_id === categoryId,
      };
    });
    const activityData = data.originalData[categoryId].activities.map(
      (itemAc, indexAc) => {
        return {
          id: itemAc.id,
          category_id: itemAc.category_id,
          name: itemAc.name,
          new_name: itemAc.new_name,
          playlist: itemAc.playlist,
          group_id: itemAc.group_id,
          created: itemAc.created,
          image: itemAc.image,
          selected: indexAc === 0,
        };
      }
    );

    const moodData = data.originalData[categoryId].activities[0].moods.map(
      (itemMood, indexMood) => {
        return {
          id: itemMood.id,
          activity_id: itemMood.activity_id,
          name: itemMood.name,
          channel_id: itemMood.channel_id,
          playlist: itemMood.playlist,
          created: itemMood.created,
          image: itemMood.image,
          selected: indexMood === 0,
        };
      }
    );

    setData({
      ...data,
      category: categoryData,
      activity: activityData,
      moods: moodData,
    });
  };

  const handleClickActivity = (categoryId, groupId) => {
    const activityData = data.activity.map((item, index) => {
      return {
        ...item,
        selected: item.group_id === groupId,
      };
    });

    const activityList = data.originalData.find(
      (item) => item.id === categoryId
    );
    const activity = activityList.activities.find(
      (itemAc) => itemAc.group_id === groupId
    );
    const moodData = activity.moods.map((itemMood, indexMood) => {
      return {
        id: itemMood.id,
        activity_id: itemMood.activity_id,
        name: itemMood.name,
        channel_id: itemMood.channel_id,
        playlist: itemMood.playlist,
        created: itemMood.created,
        image: itemMood.image,
        selected: indexMood === 0,
      };
    });

    setData({
      ...data,
      activity: activityData,
      moods: moodData,
    });
  };

  const handleClickMoods = (id) => {
    const moodData = data.moods.map((item, index) => {
      return {
        ...item,
        selected: item.id === id,
      };
    });
    setData({
      ...data,
      moods: moodData,
    });
  };

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      axios
        .post(`${baseURL}fetch-moods`, config)
        .then((res) => {
          if (Array.isArray(res.data)) {
            const categoryData = res.data.map((item, index) => {
              return {
                id: item.id,
                category_id: item.category_id,
                name: item.name,
                new_name: item.new_name,
                playlist: item.playlist,
                created: item.created,
                image: item.image,
                selected: index === 0,
              };
            });

            const activityData = res.data[0].activities.map(
              (itemAc, indexAc) => {
                return {
                  id: itemAc.id,
                  category_id: itemAc.category_id,
                  name: itemAc.name,
                  new_name: itemAc.new_name,
                  playlist: itemAc.playlist,
                  group_id: itemAc.group_id,
                  created: itemAc.created,
                  image: itemAc.image,
                  selected: indexAc === 0,
                };
              }
            );

            const moodData = res.data[0].activities[0].moods.map(
              (itemMood, indexMood) => {
                return {
                  id: itemMood.id,
                  activity_id: itemMood.activity_id,
                  name: itemMood.name,
                  channel_id: itemMood.channel_id,
                  playlist: itemMood.playlist,
                  created: itemMood.created,
                  image: itemMood.image,
                  selected: indexMood === 0,
                };
              }
            );

            setData({
              ...data,
              originalData: res.data,
              category: categoryData,
              activity: activityData,
              moods: moodData,
            });
          } else {
            props.setAlert(
              "Something went wrong. Please try again latter",
              "danger"
            );
          }
        })
        .catch((error) => {
          props.setAlert(
            "Something went wrong. Please try again latter",
            "danger"
          );
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setFinalData({
      ...finalData,
      playlist:
        data.moods.length > 0
          ? data.moods.find((item) => item.selected === true).playlist
          : "0.0.0",
    });
  }, [JSON.stringify(data.moods)]);

  return (
    <>
      <Header />
      <Alert />
      <Helmet>
        <title>Sonority | Music Track </title>
      </Helmet>
      <section className="music-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="music-box text-white">
                <h3>Music Track</h3>
                <h6 style={{ marginTop: "-10px", fontSize: "10px" }}>
                  Powered By Mubert
                </h6>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="track-name">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Track Name</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Track Name"
                          value={finalData.title}
                          onChange={(e) => handleTitleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="duration-input">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">
                          Duration
                        </label>
                        <select
                          name="duration"
                          className="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="15">15 Seconds</option>
                          <option value="45">45 Seconds</option>
                          <option value="60">60 Seconds</option>
                          <option value="120">120 Seconds</option>
                          <option value="180">180 Seconds</option>
                          <option value="custom">Custom</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {show === true ? (
                    <div className="col-md-4">
                      <div className="track-name">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Custom Duration
                          </label>
                          <input
                            type="number"
                            name="duration"
                            min="0"
                            max="600"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Duration in seconds"
                            onChange={(e) => handleCustomChangeDuration(e)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <h5 className="mt-5">Choose A Category</h5>

                <div className="row">
                  <div className="col-md-12">
                    <div className="music-icon">
                      <div className="row">
                        <div className="col-md-12">
                          <ul className="list-inline d-flex flex-wrap m-0 ">
                            {data.category.map((item, index) => (
                              <li>
                                <div
                                  onClick={() =>
                                    handleClickCategory(item.category_id)
                                  }
                                  className={
                                    "box-music " +
                                    (item.selected === true
                                      ? "music-active"
                                      : "")
                                  }
                                >
                                  <span>
                                    <img
                                      className="musicIcon"
                                      src={item.image}
                                      alt=""
                                    />
                                  </span>
                                  <h6 className="mt-3">{item.new_name}</h6>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="mt-5">Choose A Style</h5>

                <div className="row">
                  <div className="col-md-12">
                    <div className="music-icon">
                      <div className="row">
                        <div className="col-md-12">
                          <ul className="list-inline d-flex flex-wrap m-0">
                            {data.activity.map((itemAc, indexAc) => (
                              <li>
                                <div
                                  onClick={() =>
                                    handleClickActivity(
                                      itemAc.category_id,
                                      itemAc.group_id
                                    )
                                  }
                                  className={
                                    "box-music " +
                                    (itemAc.selected === true
                                      ? "music-active"
                                      : "")
                                  }
                                >
                                  <span>
                                    <img
                                      className="musicIcon"
                                      src={itemAc.image}
                                      alt=""
                                    />
                                  </span>
                                  <h6 className="mt-3">{itemAc.new_name}</h6>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="mt-5">Choose A Mood</h5>

                <div className="row">
                  <div className="col-md-12">
                    <div className="music-icon">
                      <div className="row">
                        <div className="col-md-12">
                          <ul className="list-inline d-flex flex-wrap m-0">
                            {data.moods.map((itemMod, indexMod) => (
                              <li>
                                <div
                                  onClick={() => handleClickMoods(itemMod.id)}
                                  className={
                                    "box-music " +
                                    (itemMod.selected === true
                                      ? "music-active"
                                      : "")
                                  }
                                >
                                  <span>
                                    <img
                                      className="musicIcon"
                                      src={Icon1}
                                      alt=""
                                    />
                                  </span>
                                  <h6 className="mt-3">{itemMod.name}</h6>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MusicPlayer request={finalData} />
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (message, type) => dispatch(setAlert(message, type)),
  };
};
export default connect(null, mapDispatchToProps)(MusicTrack);
