import React, { useEffect, useState } from "react";
let audio = "";
const SpeakerHead = (props) => {
  const [speaker, setSpeaker] = useState({
    name: "",
    image: "",
    hashTags: [],
    previewUrl: "",
  });
  const [playing, setPlaying] = useState("no");
  useEffect(() => {
    setSpeaker({
      name: props.speaker.name,
      image: props.speaker.image,
      hashTags: props.speaker.tags.split(","),
      previewUrl: props.speaker.preview_url,
    });

    return () => {
      setSpeaker({
        name: "",
        image: "",
        hashTags: [],
        previewUrl: "",
      });
    };
  }, []);

  useEffect(() => {
    if (playing === "no") {
      if (audio !== "") {
        audio.pause();
      }
    }
    if (playing === "yes") {
      if (audio !== "") {
        audio.play();
      }
    }
    if (playing === "loading") {
      // setTimeout(() =>{
      if (audio !== "") {
        audio.pause();
      }
      audio = new Audio(speaker.previewUrl);
      const audioPromise = audio.play();
      if (audioPromise !== undefined) {
        audioPromise
          .then(() => {
            setPlaying("yes");
          })
          .catch((error) => console.log(error, "Error playing Audio"));
      }
      // },5000)
    }
    if (audio !== "") {
      audio.addEventListener("ended", () => {
        setPlaying("no");
      });
    }
  }, [playing]);

  const handleClick = (type) => {
    if (type === "play") {
      setPlaying("loading");
    }
    if (type === "pause") {
      setPlaying("no");
    }
  };

  const handleVolumeChange = (e) => {
    if (audio !== "") {
      audio.volume = e.target.value / 100;
    }
  };
  return (
    <div className="col-md-12 mb-4" onClick={() => props.click(props.speaker)}>
      <div
        className="item-box"
        tabIndex="0"
        onKeyDown={() => props.click(props.speaker)}
      >
        <div className="user-range">
          <ul className="list-inline m-0 d-flex align-items-center">
            <li>
              <img
                src={speaker.image}
                alt="userimg"
                className="img-fluid speakerImage"
              />
            </li>
            <li>
              {playing === "loading" ? (
                <i className="fa fa-spinner fa-spin pointer" />
              ) : playing === "yes" ? (
                <i
                  className="fas fa-pause-circle pointer"
                  onClick={() => handleClick("pause")}
                >
                  {" "}
                </i>
              ) : (
                <i
                  className="fas fa-play-circle pointer"
                  onClick={() => handleClick("play")}
                >
                  {" "}
                </i>
              )}
            </li>
            <li className="mt-2">
              <input
                type="range"
                className="form-range range"
                id="customRange1"
                min="0"
                max="100"
                onChange={(e) => handleVolumeChange(e)}
              />
            </li>
          </ul>
        </div>
        <div className="user-content">
          <h6>{speaker.name}</h6>
          <p className="m-0">
            {speaker.hashTags.map((item) => "#" + item + ", ")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SpeakerHead;
