import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";
import {removeLoader, setLoader} from "../../actions/loader";
import {setAlert} from "../../actions/alert";
import {fetchTracks} from "../../actions/tracks";
import {connect} from "react-redux";
import * as queryString from "query-string";
import axios from "axios";
import {baseURL, config} from "../../globals/global";
import Alert from "../Alert/Alert";
import {fetchTranscribe} from "../../actions/transcribe";

const EditTranscribe = (props) => {

    const [transcribeData, setTranscribeData] = useState([]);
    const [txtData, setTxtData] = useState({
        id: '',
        text: ''
    });

    const [rewriteLoader, setRewriteLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);

    const fetchData = async () => {
        await props.fetchTranscribe();

        const transId = queryString.parse(props.location.search).id;

        console.log(transId);

        const rowIdDetails = props.transcribe.transcribe.find(item => item.id === transId);
        console.log(rowIdDetails);

        setTranscribeData(rowIdDetails);
        setTxtData({...txtData,text: rowIdDetails.transcribe_text,id: transId});
    }

    useEffect(()=>{

        fetchData();

    },[transcribeData]);

    useEffect(()=>{

    },[txtData]);


    const handleChange = (e) => {
        let data = transcribeData;
        console.log(data);
        data.transcribe_text = e.target.value;

        setTxtData({...txtData, text: data.transcribe_text});
        setTranscribeData(data);
    }

    const handleRewrite = (e) => {
        setRewriteLoader(true);

        axios.post(`${baseURL+'rewrite'}`, txtData,config).then((res)=>{
            if(res.data.status === true)
            {
                console.log(res.data.text);
                props.setAlert('Script Rewrite Successfully','success');
                setTxtData({...txtData, text: res.data.text});
                setRewriteLoader(false);
            }
            else if(res.data.status === false)
            {
                props.setAlert(res.data.message,'danger')
                setRewriteLoader(false);
            }
            else
            {
                props.setAlert('Something went wrong, please try again','success')
                setRewriteLoader(false);
            }
        }).catch((error) => {
            console.log(error,'Something went wrong')
            setRewriteLoader(false);
        })
    }

    const handleSave = (e) => {
        setSaveLoader(true);

        axios.post(`${baseURL+'save-script'}`, txtData,config).then((res)=>{
            if(res.data.status === true)
            {
                props.fetchTranscribe();
                props.setAlert('Script Saved Successfully','success');
                setSaveLoader(false);
                props.history.push('upload-n-transcribe');
            }
            else if(res.data.status === false)
            {
                props.setAlert(res.data.message,'danger')
                setSaveLoader(false);
            }
            else
            {
                props.setAlert('Something went wrong, please try again','success')
                setSaveLoader(false);
            }
        }).catch((error) => {
            console.log(error,'Something went wrong')
            setSaveLoader(false);
        })
    }

    return (
        <>
            <Header />
            <Helmet>
                <title>Sonority | Upload-N-Transcribe </title>
            </Helmet>
            <Alert/>
            <section className="add-account-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add-heading text-white">
                                <h4>Edit & Rewrite Transcribed Data</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <textarea style={{background: "#f2f2f2"}} className="form-control rewrite-textarea"
                                          name="text" rows="10" value={txtData.text}
                                          onChange={(e)=>handleChange(e)}
                                >
                                    {txtData.text}
                                </textarea>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-change8" onClick={(e)=>handleRewrite(e)}>
                                    {rewriteLoader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}Rewrite Script
                                </button>
                                <button className="btn btn-change8" onClick={(e)=>handleSave(e)}>
                                    {saveLoader === true ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Save Script
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
    transcribe: state.transcribe
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        setAlert: (message,type) => dispatch(setAlert(message,type)),
        removeLoader: () => dispatch(removeLoader()),
        fetchTracks: () => dispatch(fetchTracks()),
        fetchTranscribe : () => dispatch(fetchTranscribe())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    EditTranscribe
);
