import axios from 'axios';

const setAuthToken = token => {
    if(token){
        // console.log('Setting token',token)
        axios.defaults.headers.common['Authorization'] = token;
    }else{
        delete axios.defaults.headers.common['X-Auth'];
    }
}

export default setAuthToken;
