import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import logo from "../../assets/images/logo.png";
import {NavLink} from "react-router-dom";
import Footer from "../Footer/Footer";
import * as queryString from "query-string";
import {removeLoader, setLoader} from "../../actions/loader";
import {addTraining} from "../../actions/training";
import {connect} from "react-redux";

const ArticleDetails = (props) => {
    const [training, setTraining] = useState({
        categories:[],
        articles:[],
        videos:[]
    })
    const [article, setArticle] = useState({
        id:0,
        title:"",
        article:""
    })
    const articleId = queryString.parse(props.location.search).id;
    const categoryId = queryString.parse(props.location.search).cid;
    useEffect(() => {
        if(articleId !== undefined){
            const queriedCategory = props.tutorial.articles.find(item => item.category_id === categoryId)
            if(queriedCategory !== undefined)
            {
                const data = JSON.parse(queriedCategory.data);

                const queriedArticle = data.find(article => article.id === articleId);
                if(queriedArticle !== undefined)
                {
                    setArticle({
                        id:queriedArticle.id,
                        title: queriedArticle.title,
                        article: queriedArticle.content
                    })
                }
            }
        }
    },[articleId,props.tutorial.articles.length])

    useEffect(() => {
        setTraining({
            categories:props.tutorial.categories,
            articles:props.tutorial.articles,
            videos:props.tutorial.videos
        })
    },[JSON.stringify(props.tutorial)])

    useEffect(() => {
        props.addTraining();
    },[])

    return (
        <>
            <Header/>
            <section className="supportartical">
                <div className="container">
                    {console.log(article,'aaaaaaaaaaaaa')}
                    <div className="row  mt-5">
                        <div className="col-md-4" style={{background:'linear-gradient(to bottom , #521d68  , #340b53)' , height:'100%', borderRadius:'8px' , boxShadow:'0 0 16px 0 #000', padding:'32px',}}>
                            <div className="logo-sonority py-4 d-flex justify-content-center">
                                <img src={logo} alt="logo" className="img-fluid"/>
                            </div>
                            <h5 className="mt-4 mb-4 text-white">POPULAR TOPICS</h5>
                            <div className="nav flex-column float ">
                                {training.categories.map(item =><NavLink to={"article?cid="+item.id} className="nav-link" >{item.name} </NavLink> )}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="tab-content articalbgcolor" id="v-pills-tabContent">
                                <div className="tab-pane fade show active text-white p-5">
                                    <h4>{article.title}</h4>
                                    <div className="content">
                                        <div className="text-white"
                                            dangerouslySetInnerHTML={{
                                                __html: article.article
                                            }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    );
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader.loader,
    tutorial: state.training
});
const mapDispatchToProps = (dispatch) => {
    return {
        setLoader: () => dispatch(setLoader()),
        removeLoader:() => dispatch(removeLoader()),
        addTraining:() => dispatch(addTraining())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetails);
