import React, { useState } from "react";
import playIcon from "../../assets/images/playbtns.png";
import ReactPlayer from "react-player";

function Popular(props) {
  const [video, setVideo] = useState("");
  const [show, setShow] = useState(false);

  const playVideo = (url) => {
    setVideo(url);
    setShow(!false);
    console.log(url, "videoUrl");
  };

  const handleClose = () => {
    setShow(false);
    setVideo("");
  };

  return (
    <>
      <section className="popular-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="popular-head text-center text-white">
                <h2>Popular Video Tutorials</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {props.videos.map((item) => (
              <div className="col-md-3 mt-4">
                <div className="img-wrapper mb-3 mt-3">
                  <img
                    id={item.thumbnail}
                    className="img-fluid poster w-100"
                    src={item.thumbnail}
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>

                <div className="details-wrapper row mx-0">
                  <div className="play pr-3 align-self-center pointer">
                    <img
                      className="img-fluid"
                      src={playIcon}
                      alt=""
                      style={{ width: "30px" }}
                      onClick={(e) => playVideo(item.url)}
                    />
                  </div>
                  <div className="data">
                    <span class="sm-txt text-white">{item.created}</span>
                  </div>
                  <span class="sm-txt text-white">{item.title}</span>
                </div>

                {console.log(item)}
              </div>
            ))}
          </div>
        </div>
      </section>

      <div
        className="modal fade show"
        style={{ display: show === true ? "block" : "none" }}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<iframe*/}
              {/*    src="https://player.vimeo.com/video/564028727?background=1&loop=1&byline=0&title=0"*/}
              {/*    frameBorder="0"*/}
              {/*    allowFullScreen*/}
              {/*    width="600px"*/}
              {/*> </iframe>*/}
              {/*<ReactPlayer url='https://vimeo.com/564028727' width='100%' height='100%' />*/}
              <div className="row">
                <div className="col-md-12">
                  {/* <ReactPlayer url={video}  playing={show}/> */}
                  <div className="embed-responsive embed-responsive-1by1">
                    <iframe
                      width="560"
                      height="315"
                      src={`${video}?`}
                      title="Sonority Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popular;
