import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/src/plugin/regions";
import axios from "axios";
import { baseURL, config } from "../../globals/global";

const AudioFile = (props) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [currentSelection, setCurrentSelection] = useState({
    startTime: 0,
    endTime: 0,
    duration: 0,
  });

  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  useEffect(() => {
    if (props.campaign.url !== "") {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        responsive: true,
        height: 45,
        progressColor: "#f56262",
        cursorWidth: 5,
        plugins: [
          RegionsPlugin.create({
            regionsMinLength: 2,
            regions: [
              {
                start: 0,
                end: 5,
                loop: false,
                drag: true,
                resize: true,
                color: "rgba(48, 107, 177, 0.7)",
              },
            ],
          }),
        ],
        dragSelection: "true",
      });

      wavesurfer.current.on("ready", waveSurferReady);

      if (props.campaign.url !== "") {
        wavesurfer.current.load(props.campaign.url, setLoading1(true));
      }

      wavesurfer.current.on("finish", function () {
        setPlaying(false);
      });

      wavesurfer.current.on("region-updated", function (region) {
        const startTime = parseInt(region.start.toFixed(2));
        const endTime = parseInt(region.end.toFixed(2));

        setCurrentSelection({
          startTime: startTime,
          endTime: endTime,
          duration: endTime - startTime,
        });
      });
    }

    return () => {
      if (wavesurfer.current !== null) {
        wavesurfer.current.destroy();
      }
    };
  }, [props.campaign.url]);

  const waveSurferReady = () => {
    setLoading1(false);
    const durationOnLoad = wavesurfer.current.getDuration();
    setCurrentSelection({
      ...currentSelection,
      endTime: parseInt(durationOnLoad),
      duration: parseInt(durationOnLoad),
    });
  };

  const handlePlay = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
    wavesurfer.current.on("audioprocess", function () {});
  };

  const handleTrim = () => {
    setLoading(true);
    wavesurfer.current.playPause();

    try {
      const body = {
        audiourl: props.campaign.url,
        campaignid: props.campaign.campaignId,
        end_time: currentSelection.endTime,
        name: props.campaign.campaignName,
        start_time: currentSelection.startTime,
      };
      axios
        .post(`${baseURL}trim-audio`, body, config)
        .then((res) => {
          if (res.data.status === true) {
            setLoading(false);
            props.click({
              campaignId: body.campaignid,
              campaignName: body.name,
              url: body.audiourl,
              startTime: body.start_time,
              endTime: body.end_time,
              duration: currentSelection.duration,
            });
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="audio-upload">
        <div className="audio-box-line mt-4 p-1">
          <div className="row p-3">
            {/*<div className="col-md-1 align-items-center">*/}
            {/*    <div className="audio-clip">*/}
            {/*        <div className="form-group">*/}
            {/*            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={duration.currentTime}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="col-md-12">
              <div className="wave-line" ref={waveformRef}>
                {loading1 === true ? (
                  <div class="d-flex justify-content-center">
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#ea5e5f" }}
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 p-3">
          <div className="col-md-2">
            <div className="start-time">
              <div className="form-group m-0">
                <label htmlFor="exampleInputEmail1" className="m-0">
                  START TIME
                </label>
                <input
                  type="text"
                  className="form-control m-0"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={`${currentSelection.startTime} Sec`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="start-time">
              <div className="form-group m-0">
                <label htmlFor="exampleInputEmail1" className="m-0">
                  END TIME
                </label>
                <input
                  type="text"
                  className="form-control m-0"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={`${currentSelection.endTime} Sec`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="play-btns text-center" onClick={handlePlay}>
              {playing === true ? (
                <i className="far fa-pause-circle wavePlayIcon text-white"> </i>
              ) : (
                <i className="far fa-play-circle wavePlayIcon text-white"> </i>
              )}
            </div>
          </div>
          <div className="col-md-2">
            <div className="start-time">
              <div className="form-group m-0">
                <label htmlFor="exampleInputEmail1" className="m-0">
                  DURATION TIME
                </label>
                <input
                  type="text"
                  className="form-control m-0"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={`${currentSelection.duration} Sec`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="play-btns text-center pointer" onClick={handleTrim}>
              <i className="fa fa-arrow-right wavePlayIcon text-white"> </i>
              {loading === true ? (
                <div class="text-center" style={{ display: "inline-block" }}>
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ color: "#fff" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioFile;
