import React, { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import { Link } from "react-router-dom";
import { baseURL, config } from "../../globals/global";
import VoiceCard from "../../assets/images/playcard.png";
import MusicCard from "../../assets/images/playcard2.png";
import AudioCard from "../../assets/images/playcard3.png";
import WaveCard from "../../assets/images/playcard4.png";
import { removeLoader, setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";
import { fetchTracks } from "../../actions/tracks";
import { connect, useDispatch } from "react-redux";
import axios from "axios";

const VideoFile = (props) => {
  const [localValue, setLocaleValue] = useState({
    image: "",
    type: "",
    name: "",
    created: "",
    editPath: "",
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLocaleValue({
      image: props.type === "Wave" ? WaveCard : "",
      type: props.type,
      name: props.item.podcast_name,
      created: props.item.created,
      editPath: "",
    });

    return () => {
      setLocaleValue({
        image: "",
        type: "",
        name: "",
        created: "",
        audioFile: "",
        editPath: "",
      });
    };
  }, []);

  const handleDeleteClick = () => {
    if (props.type === "Wave") {
      deleteItem("delete-wave-video", props.item.id);
      console.log(props.item.id);
    }
  };

  const deleteItem = (type, id) => {
    setLoader(true);
    try {
      axios
        .post(`${baseURL + type}`, { id: id }, config)
        .then((res) => {
          if (res.data.status === true) {
            props.setAlert(res.data.message, "success");
            props.fetchTracks();
            dispatch(fetchTracks());
          } else if (res.data.status === false) {
            props.setAlert(res.data.message, "danger");
          } else {
            props.setAlert("Something went wrong", "danger");
          }
          setLoader(false);
        })
        .catch((error) => {
          console.log(error, "Something went wrong");
          setLoader(false);
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
      <Alert />
      <div className="sec-2-box">
        <img src={localValue.image} alt="musics" className="img-fluid" />
        <div className="musice-title">
          <span>{localValue.type} Track</span>

          <div className="edit-delete-icon">
            <ul className="list-inline d-flex">
              {/*<li>{localValue.editPath === null ? null : <Link to={localValue.editPath} ><span className="pointer"><i className="far fa-edit"> </i></span></Link>}</li>*/}
              <li>
                {loader ? (
                  <span className="pointer">
                    <i className="fa fa-spinner fa-spin text-white"> </i>
                  </span>
                ) : props.auth.user.is_client_account !== "1" ? (
                  <span className="pointer" onClick={() => handleDeleteClick()}>
                    <i className="far fa-trash-alt"> </i>
                  </span>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
        <div className="play-songname perfect_align">
          <div className="songname text-white ml-3">
            <span
              data-toggle="tooltip"
              data-placement="top"
              title={localValue.created}
            >
              {localValue.created}
            </span>
            <h6
              data-toggle="tooltip"
              data-placement="top"
              title={localValue.name}
              className="text-white"
            >
              {localValue.name}
            </h6>
          </div>
          <div className="download-icon">
            {props.type !== "Music" ? (
              props.item.is_active === "3" ? (
                <a
                  href={
                    baseURL + "/download-file?url=" + props.item.output_video
                  }
                >
                  <span className="ml-3 text-white download pointer">
                    <i className="fas fa-download"> </i>
                  </span>
                </a>
              ) : props.item.is_active === "2" ? (
                <div
                  class="d-flex justify-content-end"
                  style={{ marginLeft: "20px" }}
                >
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ color: "#fff" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  tracks: state.tracks,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    setAlert: (message, type) => dispatch(setAlert(message, type)),
    removeLoader: () => dispatch(removeLoader()),
    fetchTracks: () => dispatch(fetchTracks()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoFile);
