import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../globals/global";
import { removeAlert, setAlert } from "../../actions/alert";
import {
  getBrandingDataFromServer,
  clearBrandingData,
} from "../../actions/branding";
import axios from "axios";
import Alert from "../Alert/Alert";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
// import BusinessNav from "../businessNav/BusinessNav";

const Rebranding = ({ auth: { user, loading }, removeAlert, setAlert }) => {
  const rebrandData = useSelector((state) => state.brandingdata);
  const auth = useSelector((state) => state.auth);

  const [selectedFile, setSelectedFile] = useState({});
  const [user_id, setUserId] = useState("");
  const [brandingName, setBrandingName] = useState("");
  const [loader, setLoader] = useState(false);
  const [accountType, setAccountType] = useState([]);
  const dispatch = useDispatch();
  const [button, setButton] = useState("Delete");
  const [deleteData, setDeletedData] = useState({
    id: "",
  });
  const [showDelete, setShowDelete] = useState(false);

  // useEffect(() => {
  //   setAccountType(loading || !user ? [] : [...user.account_type]);
  // }, [user, loading]);
  useEffect(() => {
    if (user) {
      setUserId(loading || !user.id ? "" : user.id);
    }
  }, [user, loading]);

  const uploadBranding = (e) => {
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setSelectedFile(e.target.files[0]);
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  const sendBrandingData = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("logo", selectedFile);
    formData.append("name", brandingName);
    formData.append("user_id", user_id);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const res = await axios.post(
        `${baseURL}create-reseller`,
        formData,
        config
      );

      if (res.data.status === true) {
        setLoader(false);
        setAlert("Application Rebranded", "success");
        dispatch(getBrandingDataFromServer({ user_id: user_id }));
        setSelectedFile({});
        setBrandingName("");
      } else if (res.data.status === false) {
        setLoader(false);
        setAlert(res.data.message, "danger");
      }
    } catch (err) {
      setLoader(false);
      console.log(err.response);

      setSelectedFile({});
    }
  };

  // Remove rebranding end point

  const onConfirm = async () => {
    setButton("Deleting...");
    let options = {
      headers: {
        Authorization: auth.token,
      },
    };

    await axios
      .post(`${baseURL}delete-Reseller`, deleteData, options)
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          dispatch(
            getBrandingDataFromServer({
              user_id: rebrandData.brandingdata.user_id,
            })
          );
          dispatch(setAlert(res.data.message, "success"));
          // dispatch(clearBrandingData(auth.token));
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });

    setShowDelete(false);
    setButton("Delete");
  };

  const onCancel = () => {
    setShowDelete(false);
  };

  const removeWl = (id) => {
   // console.log(id, "id");

   // console.log("delete");
    setDeletedData({ ...deleteData, id: id });
    setShowDelete(true);
  };

  return (
    <FbStyle>
      <div className="contract-assets">
        <Alert />
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              {/* <BusinessNav /> */}
              <div className="col-9 mb-5">
                <div className="row bg-table justify-content-center align-items-center">
                  <div
                    className="col-11 p-0"
                    style={{
                      height: "20vh",
                      width: "100%",
                      backgroundColor: "#363839",
                      border: "2px white dashed",
                    }}
                  >
                    <form
                      onSubmit={(e) => e.target.preventDefault()}
                      style={{
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div className="upload-btn-wrapper">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <button
                            style={{
                              backgroundColor: "#363839",
                              color: "white",
                              border: "none",
                              fontSize: "1rem",
                            }}
                          >
                            {Object.keys(selectedFile).length === 0 &&
                            selectedFile.constructor === Object
                              ? "Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed)"
                              : selectedFile.name}
                          </button>
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                            style={{
                              display: "block",
                              color: "white",
                              fontSize: "1.7rem",
                              paddingTop: "10px",
                            }}
                          ></i>
                        </div>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          name="file"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onChange={(e) => {
                            uploadBranding(e);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col col-lg-6 col-12 p-1">
                    <div className="form-group form-group-mb">
                      <label className="input-label">Brand Name </label>
                      <input
                        className="form-control input-dark-accnt personal-info  w-100"
                        type="text"
                        name="firstName"
                        placeholder="Enter your brand Name"
                        value={brandingName}
                        onChange={(e) => setBrandingName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col col-md-12 text-center mt-3">
                    {loader ? (
                      <button
                        className="btn btn-3 accnt-mngmt-btn mt-4"
                        type="button"
                        disabled
                        style={{
                          background: "#1cb7f2",
                          font: "16px",
                          color: "white",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm text-light mx-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <strong>Loading...</strong>
                      </button>
                    ) : (
                      <button
                        className="brandingBtn btn-3 accnt-mngmt-btn mt-4"
                        onClick={sendBrandingData}
                        disabled={
                          (Object.keys(selectedFile).length === 0 &&
                            selectedFile.constructor === Object) ||
                          brandingName === ""
                            ? true
                            : false
                        }
                        style={{ width: "20%" }}
                      >
                        Save
                      </button>
                    )}
                    <div className="brandingBtn btn-3 accnt-mngmt-btn mt-4">
                      {rebrandData.brandingdata ? (
                        <button
                          type=""
                          onClick={(e) => removeWl(rebrandData.brandingdata.id)}
                          className="demoLink mt-0"
                        >
                          Remove Rebranding
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SweetAlert
        style={{ color: "gray" }}
        warning
        showCancel
        confirmBtnText={button}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={(e) => onConfirm(e)}
        onCancel={(e) => onCancel(e)}
        focusCancelBtn
        show={showDelete}
      >
        Do you want to delete this Rebranding
      </SweetAlert>
    </FbStyle>
  );
};

const FbStyle = styled.div`
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: #171717 !important;
    color: white;
    cursor: pointer;
  }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  removeAlert,
  setAlert,
})(Rebranding);
